/* eslint-disable */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable-next-line sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions*/
import { ReactComponent as Addimage } from "../../../assets/images/ImageAdd.svg";
import { ReactComponent as Successimage } from "../../../assets/images/success-icon.svg";
import React, { useEffect, useState, useContext } from "react";
import Eposchedule from "../AddCoCustomer/Eposchedule";
import AddCoCustomer from "../AddCoCustomer/AddCocustomer";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as Alertimage } from "../../../assets/images/no-records-found.svg";
import { ReactComponent as WarningIcon } from '../../../assets/images/warningIcon.svg';

import { AgreementContext } from "../../../context/AgreementContext";
import AgreementReturn from "../../Rental/View Agreement/AgreementReturn";
import SecondFactor from "../TwoFactorPopup/TwoFactorPopup";
import { fieldDeviceStyles } from "../../../JSstyles/fieldDeviceStyles";

import {
  GetDeliveryAddress,
  GetTimeSlots,
  // Getglobalcustomerid,
  UpdateAddressInfo,
  // updatecustomer,
  FinishdeliveryStatus,
  CoworkerRequired,
  Confirmdelivery,
  GetstateDropdown,
  GetSuggestedAddress,
  getAgreementInfo,
  GenerateReceipt,
  getCurrentInfo,
  getRadiusStores,
  sendEPO,
  GetRole,
  LoadDeliveryreceipt,
  getnextpaymentamount,
  getAgreementDelivery,
} from "../../../api/user";
import { addDays } from "date-fns";
import {
  RACRadio,
  RACTextbox,
  RACDatePicker,
  RACButton,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACSelect,
  RACModalCard,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Box,
  RACToggle,
  RACCheckBox
} from "@rentacenter/racstrap";

import clsx from "clsx";
import { agreementDeliveryStyles } from "../../../JSstyles/agreementDeliveryStyles";
import { agreementGlobalStyles } from "../../../JSstyles/agreementGlobalStyles";
import { OriginContext } from "../../../context/OriginContext";
import { AMOrigin } from "../../../constants/constants";
import { AppRoute } from "../../../config/route-config";
import { ContainerContext } from "../../../app/App";
import { CustomPropInterface } from "../../../index";
import { FeatureFlagContext } from "../../../context/FeatureFlagContext";
import validateAddressFn from "../../../common/vaidateAddressFn";


let addRowArray: any;
// eslint-disable-next-line prefer-const
addRowArray = [];
// let BindtimeslotId = '';
let BindtimeslotDate = "";
let ExBindtimeslotDate = "";

interface ParamType {
  customerId: string;
  agreementId: string;
}

export interface RadiusStoreType {
  response: [
    {
      neighborStoreId: string;
      neighborStoreNumber: string;
    }
  ]
}

export interface ComboBoxOption {
  value: string;
  label: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function Deliverymethod(props: any) {

  const classes1 = fieldDeviceStyles();
  const classes = agreementDeliveryStyles();
  const globalStyle = agreementGlobalStyles();
  const { customerId } = useParams<ParamType>();
  const { agreementId } = useParams<ParamType>();
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [returnbtnRdn, setreturnbtnRdn] = useState(false);
  const [confirmFlow, setconfirmFlow] = useState(false);
  const history = useHistory();
  const { redirectOrigin } = useContext(OriginContext);
  const [coCustomerId, setcocustomerId] = useState("");
  const [coCustomerName, setcoCustomerName] = useState("");
  const [TimeSlotYes, setTimeSlotYes] = useState(false);
  const [desktopView, setdesktopView] = useState(true);
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const [canSuggestAddress, setCanSuggestAddress] = useState(false);
  const [cantSuggestAddress, setCantSuggestAddress] = useState(false);
  const [validatedAddresses, setValidatedAddress] = useState<any>({});
  const [canEnableGoogleValdation, setCanEnableGoogleValdation] = useState(false);
  const [verificationWarningPopup, setverificationWarningPopup] = useState(false)
  const [verifcationRequiredContinue, setVerifcationRequiredContinue] = useState(false)
  const {
    viewAgrData,
    setdeliveryMethodData,
    SetaddressId,
    addressId,
    resumebtnFeatureFlag,
    setNewAppointmentId,
    manualSignatureEnabled,
    setManualSignatureEnabled,
    deliveryMethodData,
    rescheduleEnable,
    setRescheduleEnable,
    datePickerEnable,
    setdatePickerEnable,
    createAAgreementTiemslot,
    setcreateAAgreementTiemslot,
    getCustomerResponse
  } = useContext(AgreementContext);
  const [AppoinmentSource, setAppoinmentSource]: any = React.useState("");

  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });

    console.log("Footer contex", data);
  };

  // Setting up State Variables for the Component

  // State Variables for the  the ID's that we use in the component
  const [InventoryNumber, setInventoryNumber] = useState<any>();
  const [appointmentId, setappointmentId] = useState<any>("");
  const [ContextStartDate, SetContextStartDate] = useState("");
  const [ContextEndDate, SetContextEndDate] = useState("");
  const [agreementid, Setagreementid] = useState("");
  const [IdAddress, SetIdAddress] = useState("");
  const [Coworkers, SetCoworkers]: any = useState("");
  const [getaddress, SetGetAddress] = useState([]);
  const [timeslotid, Settimeslotid] = useState("");
  const [DeliveryRdo, SetDeliveryRdo] = useState("");
  const [CarryoutRdo, SetCarryouutRdo] = useState("");
  const [Deliverytype, SetDeliverytype] = useState("D");
  const [DeliverytypeExc, SetDeliverytypeExc] = useState("P");
  const [checkDeliverytype, SetcheckDeliverytype] = useState("DEL");
  const [checkDeliverytypeExc, SetcheckDeliverytypeExc] = useState("DEL");
  const [CarryinRdoExc, SetCarryinRdoExc] = useState("");
  const [DeliveryRdoExc, SetDeliveryRdoExc] = useState("");
  const [CoworkersExc, SetCoworkersExc]: any = useState("");
  console.log(checkDeliverytype, "datas ae returedn")

  // State Variables for the Delivery Address in Delivery Address Heading

  const [Addressline1, SetAddressline1] = useState("");
  const [Addressline2, SetAddressline2] = useState("");
  const [Zip, SetZip] = useState("");
  const [City, SetCity] = useState("");
  const [State, SetState] = useState("");
  const [pdfresponse, setpdfresponse] = useState([]);

  // State Variables for the Pop-Up display on clicking Select Other Address

  const [Popup, SetPopup] = useState(false);

  // State Variables for the Delivery Address that is displayed in the input field in the Pop-up

  const [Line1Popup, SetLine1Popup] = useState("");
  const [Line2Popup, SetLine2Popup] = useState("");

  //return
  const [ReturnDeliveryCompleted, SetReturnDeliveryCompleted] = useState(false);
  const [ReverseConfirmation, setReverseConfirmation] = useState(false);

  const [SOTwoFactorReturnEnabled, setSOTwoFactorReturnEnabled] =
    useState(false);
  const [currentUserRole, setCurrentUserRole] = React.useState("");
  const [ZipPopup, SetZipPopup] = useState("");
  const [CityPopup, SetCityPopup] = useState("");
  const [StatePopup, SetStatePopup] = useState("");
  const [StatePopupId, SetStatePopupId] = useState("");
  const [Statedrop, SetStatedrop]: any = useState([]);

  // State Variables for adding rows in the Pop-Up below the input field for each value entered
  const [addRow, SetAddRow] = useState([]);
  const [timeSlotData, SettimeSlotData]: any = useState([]);
  const [coWorkerRequiredArray, setcoWorkerRequiredArray]: any = useState([]);
  const [ResendVopen, setResendVopen] = useState(false);
  const [resendsuccess, setResendsuccess] = useState(false);
  const [textMsg, settextMsg] = useState(false);
  const [sendEmail, setsendEmail] = useState(true);
  const [emailText, setemailText] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [EmailErrVal, setEmailErrVal] = useState(false);
  const [welcomeEnable, setwelcomeEnable] = useState(false);
  const [phoneErrMsg, setphoneErrMsg] = useState("");
  const [emailErrMsg, setemailErrMsg] = useState("");
  // State Variables for showing details in Delivery Details heading
  // eslint-disable-next-line sonarjs/no-duplicate-string
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [startDateExc, setStartDateExc] = useState(moment().format("YYYY-MM-DD"));
  const [Time, SetTime] = useState("");
  const [Instructions, SetInstructions] = useState("");
  const [InstructionsExc, SetExcInstructions] = useState("");
  const [Errorpopup, SetErrorpopup] = useState(false);
  // State Variables for displaying 5 days from the Selected Date
  const [TEMP, SetTEMP] = useState("0");
  const [inventoryid, Setinventoryid] = useState([]);
  const [agreementNumber, SetagreementNumber] = useState("");
  const [Starttime, SetStarttime]: any = useState("");
  const [Endtime, SetEndtime]: any = useState("");
  const [enableadd, Setenableadd] = useState(true);
  const [enableFinish, SetenableFinish] = useState(true);
  // const [enableSave, SetenableSave] = useState(true);
  const [editdisable, Seteditdisable] = useState(false);
  const [InputGrid, SetInputGrid] = useState(false);
  const [EpoProps, SetEpoProps] = useState<any>();
  const [savedisable, Setsavedisable] = useState(false);
  const [conditionLoader, SetconditionLoader] = useState(true);
  const [StateconditionLoader, SetStateconditionLoader] = useState(true);
  const [ValidateIsOpen, setValidateIsOpen] = useState(false);
  const [BtnIsdisabled1, setBtnIsdisabled1] = useState(true);
  const [BtnIsdisabled2, setBtnIsdisabled2] = useState(false);
  const [SuggestedAddress, setSuggestedAddress]: any = useState();
  
  const [RescheduleEventId, SetRescheduleEventId] = useState("");
  const [masterLoader, setmasterLoader]: any = React.useState(true);
  const [defaultSuggestedAddress, setdefaultSuggestedAddress] = useState("SUG");
  const [Opendeliveryconfirmpopup, SetOpendeliveryconfirmpopup] =
    useState(false);
  const [Openreturnconfirmpopup, SetOpenreturnconfirmpopup] = useState(false)
  const [RescheduleProps, SetRescheduleProps] = useState("Schedule");
  const [SaveTrigger, SetSaveTrigger] = useState(false);
  const nextpaymentobj = {
    nextamountdue: "",
    nextduedate: "",
  };
  const [nextpaymentinfo, setnextpaymentinfo] = useState(nextpaymentobj);
  const [GCID, SetGCID] = useState("");
  const [DisplayErrorMessage, SetDisplayErrorMessage] = useState("");
  const [BindtimeslotId, setBindtimeslotId] = useState("");
  const [hideswitchoutbutton, sethideswitchoutbutton] = useState(true);
  const [cusName, setCustomerName] = useState<any>();
  const [AgrNumber, setAgrNumber] = useState<any>();
  const [deliveryStatus, SetdeliveryStatus] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  // eslint-disable-next-line prefer-const
  let [newDeliveryAddress, setNewDeliverAddress] = useState<any>();
  const [addressErrorMsg, setAddressErrorMsg]: any = useState("");
  const [MaxDate, setMaxDate] = useState('');
  const [dateError, setdateError] = useState('');
  const [previousBtnEnable, setpreviousBtnEnable] = useState(false);
  const [timeslotCount, settimeslotCount] = useState<any>();
  const [getAgreementDeliveryRes, setgetAgreementDeliveryRes] = useState<any>('')
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const [LMDConfigurable, setLMDConfigurable] = useState(false);
  const [inventoryStorePickUp, setInventoryStorePickUp] = useState('');
  const defaultStore =  {
    value: 'Select',
    label: 'Select',
  };
  const [radiusStores, setRadiusStores] = useState<ComboBoxOption[]>([defaultStore]);
  const [radiusSelected, setRadiusSelected] = useState<string>('Select');
  
  const [getAgreementInfoRes, setgetAgreementInfoRes] = useState<any>('')
  const [agrInfoResp, setagrInfoResp] = useState<any>('')

  // eslint-disable-next-line sonarjs/cognitive-complexity

  const searchParams = new URLSearchParams(window.location.search);

  // Get specific query parameters
  const exchangeAgreementId = searchParams.get('exagreementid');
  const [openReturnContent, setOpenReturnContent] = useState<boolean>(false);
  const [exReturnTime, setExReturnTime] = useState<any>('');
  // const [exReturnContextStartDate, SetExReturnContextStartDate] = useState<any>("");
  // const [exReturnContextEndDate, SetExReturnContextEndDate] = useState<any>("");
  const [exReturnTimeSlotId, SetExReturnTimeSlotId] = useState<any>("");
  const [exReturnBindtimeslotId, SetExReturnBindtimeslotId] = useState<any>("");
  const [getagreementResponse, setAgreementResponse] = useState<any>("")
  const [inventoryNumberExc, setInventoryNumberExc] = useState()
  const [inventoryIdExc, Setinventoryidexc] = useState([])
  const [agrNumberExc, setAgrNumberExc] = useState<any>();
  const [slicetimeExc, setslicetimeExc] = useState<any>("")
  const [modifiedtimeExc, setmodifiedEndtimeExc] = useState<any>(false)
  let [colourExc, setcolourExc] = useState<any>("")
  const [timeSlotDataExc, SettimeSlotDataExc]: any = useState([]);


  // const [exReturnStartDate, SetExReturnStartDate] = useState<any>("");
  console.log({ exReturnTime, exReturnBindtimeslotId, exReturnTimeSlotId, startDateExc }, "exReturnTimeeeeeeee")
  console.log(Time, "Timeeeeeee")
  useEffect(() => {
    console.log('WindowSize', window.innerWidth)
    windowSizeFn()
  }, [])
  useEffect(() => {
    if (getCustomerResponse && (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verificationStatus != 'COMPLETED' && getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verificationStatus != 'NOTREQUIRED') && featureFlagDetails?.CustomerVerificationOptimization == 1) {
      setverificationWarningPopup(true)
    }
  }, [getCustomerResponse])





  useEffect(() => {
    getAgreementDeliveryFn()
  }, [LMDConfigurable])
  useEffect(() => {
    let employeeIdGA: any
    let loggedRole: any
    const GettingRole = async () => {
      const userResponse = await getCurrentInfo();
      console.log("getCurrentUser Response", userResponse);
      const empId = userResponse?.data?.employeeId;
      const mailId = userResponse?.data?.email.toLowerCase();
      const id = userResponse?.data?.employeeId ? empId : mailId;
      let storesPayload: any;
      // eslint-disable-next-line prefer-const
      storesPayload = { coworkerId: id };
      // eslint-disable-next-line no-console
      console.log("coworkerId", storesPayload);
      const userRole = await GetRole(storesPayload);
      const coworkerRole = userRole.data.coworkerProfile.role;
      setEmployeeId(userRole?.data?.coworkerProfile?.employeeId);

      // eslint-disable-next-line no-console
      console.log("Logged In user Role : ", coworkerRole);

      setCurrentUserRole(coworkerRole);
      loggedRole = coworkerRole;
      employeeIdGA = userRole?.data?.coworkerProfile?.employeeId;
    };
    if (containerData !== undefined) {
      console.log("containerData", containerData);

      console.log("containerDataRole", containerData?.GetRole());
      setEmployeeId(containerData?.GetEmployeeId());
      setCurrentUserRole(containerData?.GetRole());
      loggedRole = containerData.GetRole()
      employeeIdGA = containerData?.GetEmployeeId()
    } else {
      GettingRole();
    }
    window.dataLayer?.push({
      'event': 'Loaded the Rental-Agreement Delivery Schedule page',
      'customerId': customerId,
      'storeNo': storeNumber,
      'agreementNo': agreementId,
      'employeeId': employeeIdGA,
      'role': loggedRole,
      'currentScreen': 'Rental-Agreement Schedule page',
      'previousScreen': 'Initial Payment',
    });
  }, [containerData]);

  const determineLocationId = () : any => {
    
    if ( radiusSelected != 'Select' ) {
      return radiusSelected;      
    }

    return;
  }

  const buildPayload = (locationId: string, parentAppointmentID: string, numOfCoworkers: string, eventType: string ) => {
    return {      
        storeNumber: storeNumber,
        address_id: IdAddress,
        eventInformation: {
          partyId: customerId,
          agreementId: agreementId,
          inventoryIds: inventoryid,
          type: eventType,
          eventDate: moment(startDate).format("MM/DD/YYYY"),
          eventSource: "Store",
          timeSlot: {
            timeSlotId: timeslotid,
          },
          eventInstruction: Instructions,
          requiredCoworkers: numOfCoworkers,
          racdbSourced: true,
          agreementNumber: AgrNumber,
          customerName: cusName,
          isLoaner: "Y",
          loanerCreate: {
            storeNumber: storeNumber,
            timeSlot: timeslotid,
            customerId: String(customerId),
            agreementNumber: AgrNumber,
            inventories: InventoryNumber,            
            appointmentInfo: {
              appointmentType: "STOPIC",
              appointmentSource: AppoinmentSource,
              locationId: locationId,
              parentAppointmentId: parentAppointmentID,            
              appointmentDate: startDate,
              requiredCoworkers: numOfCoworkers,
              instructions: Instructions,
            },
          },
        },
      };
  }

  const createStorePickupEvent = async ( apiResult : any, numOfCoworkers: string, returningAgreement: boolean ) : Promise<any> => {
   
    if ( apiResult && apiResult.status == 200 ) {
      const parentAppointmentID = String(apiResult.data?.appointmentInfo?.appointmentId);
      // we have created the parent appointment since store pickups are ONLY created if there is a parent appointment
      const locationId = determineLocationId();

      // only for Delivery Type and if turned on for the store and if the coworker selected a radius store
      if ( (Deliverytype == "D" ||  Deliverytype == "P") &&  LMDConfigurable && locationId && parentAppointmentID ) {
        let eventType = "D";
        if ( returningAgreement ) {  // we are returning the agreement so the Delivery Type is Pickup P not Delivery since we are getting 
          eventType = "Return";
        }
        numOfCoworkers= numOfCoworkers ? numOfCoworkers : '1';

        // create the store pickup to the radius store selected 
        const payload = buildPayload( locationId, parentAppointmentID, numOfCoworkers , eventType);
        await FinishdeliveryStatus(payload);        
      }
    }

    return;
  }

  const buildRadiusStores = ( resp : any ) : void => {
    if ( resp && resp.data ) {
      let outputFromSvc: RadiusStoreType = resp.data;
      if ( outputFromSvc && outputFromSvc.response?.length > 0 ) {
        let allStores :ComboBoxOption[] = [defaultStore];
        outputFromSvc.response.forEach( (oneStore) => {
          const st : ComboBoxOption = {
            value: oneStore.neighborStoreId,
            label: oneStore.neighborStoreNumber
          }
          allStores.push(st);
        });
        
        setRadiusStores(allStores);
      }
    }
  }
  //LMD configurable  
  const getEnableLMD = async () => {
    const bodyObj = {
      storeNumbers: [storeNumber],
      paramKeyNames: ['EnableLMD']
    }

    const enableAccessLMD = await CoworkerRequired(bodyObj);
    return enableAccessLMD?.data.storeProfileResponse.configDetails[0].configDetails[0].paramValue;
  }

  const getAgreementDeliveryFn = async () => {
    setmasterLoader(true)
    
    let showLMD = !props.isReturn && (props.reScheduleData == undefined || props.reScheduleData.deliveryType == "");
    const enableLMD = await getEnableLMD();
      
    let getAgreementInfoRes = await getAgreementInfo(agreementId);
    setgetAgreementInfoRes(getAgreementInfoRes?.data)
    setmasterLoader(false)
    debugger;
    if (getAgreementInfoRes?.data?.agreementInformation?.appointmentId) {
      setmasterLoader(true)
      let getAgreementDeliveryRes1 = await getAgreementDelivery(agreementId, getAgreementInfoRes?.data?.agreementInformation?.appointmentId)
      setmasterLoader(false);
      debugger;
      if (getAgreementDeliveryRes1?.status == 200 && getAgreementDeliveryRes1?.data) {
        if (getAgreementDeliveryRes1?.data?.appointmentInfo?.appointmentStatus == 'TD' || getAgreementDeliveryRes1?.data?.appointmentInfo?.appointmentStatus == 'CMP') {
          setdatePickerEnable(true);
          setRescheduleEnable(true);
        }
        setgetAgreementDeliveryRes(getAgreementDeliveryRes1?.data)
      }
    }
    else if (createAAgreementTiemslot) {
      setdatePickerEnable(true);
      setRescheduleEnable(true);
    }

    if ( showLMD ) {      
      if ( enableLMD === '1' ) {        
        buildRadiusStores( await getRadiusStores(storeNumber) );      
        setLMDConfigurable(true);
      }
    }

  }

  const windowSizeFn = async () => {
    if (window.innerWidth <= 1280) {
      settimeslotCount(3)
      setdesktopView(false);
      setdatePickerEnable(false)
    }
    else {
      setRescheduleEnable(false)
      settimeslotCount(5)
      setdesktopView(true);
      setdatePickerEnable(false)

    }
  }
  useEffect(() => {
    if (TimeSlotYes && timeSlotData.length > 0) {
      let yourDate = timeSlotData[0]['Date'];
      if (timeSlotData[0]['Date'].length) {
        const pastDateCheckRes = isPastDateCheck(yourDate)
        if (pastDateCheckRes) {
          setpreviousBtnEnable(true)
        }
        else {
          setpreviousBtnEnable(false)
        }
      }

    }

    console.log("timeSlotDataCheck", timeSlotData)
  }, [timeSlotData])

  const isPastDateCheck = (dateString: any) => {
    let date1: any = ''
    let date2: any = ''
    date2 = date2 = new Date(dateString)
    date1 = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
    const differenceInMilliseconds = date2 - date1;
    let differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    differenceInDays = Math.ceil(differenceInDays)
    if (differenceInDays > 0) {
      return true
    }
    return false;
  }
  useEffect(() => {
    /* eslint-disable no-console */
    debugger
    console.log("Props Log", props);

    if (props !== undefined && props.reScheduleData !== undefined) {
      if (
        props.AgrNum != undefined &&
        props.AgrNum != null &&
        props.InvId != undefined &&
        props.InvId != null
      ) {
        Setinventoryid(props.InvId);
        SetagreementNumber(props.AgrNum);
      }

      if (
        props.globalCustomerId != undefined &&
        props.globalCustomerId != null
      ) {
        SetGCID(props.globalCustomerId);
      }

      SetRescheduleProps("Reschedule");
      getCoworkerRequiredData();
      getStatedata();
      GetAddressMethod();
      // getInventoryId();
      setmasterLoader(false);
      GetTimeSlot(
        props.reScheduleData.appointmentDate !== undefined &&
          props.reScheduleData.appointmentDate !== ""
          ? props.reScheduleData.appointmentDate
          : "",
        props?.reScheduleData?.appointmentSource
      );

      GetTimeSlotExc(
        props.reScheduleData.appointmentDate !== undefined &&
          props.reScheduleData.appointmentDate !== ""
          ? props.reScheduleData.appointmentDate
          : "",
        props?.reScheduleData?.appointmentSource
      );
      // eslint-disable-next-line no-console
      console.log(coWorkerRequiredArray);
      setBindtimeslotId(
        props.reScheduleData.timeSlotId !== undefined &&
          props.reScheduleData.timeSlotId !== ""
          ? props.reScheduleData.timeSlotId
          : ""
      );
      SetExReturnBindtimeslotId(props.reScheduleData.timeSlotId !== undefined &&
        props.reScheduleData.timeSlotId !== ""
        ? props.reScheduleData.timeSlotId
        : "");
      // BindtimeslotId =
      //   props.reScheduleData.timeSlotId !== undefined &&
      //   props.reScheduleData.timeSlotId !== ''
      //     ? props.reScheduleData.timeSlotId
      //     : '';
      BindtimeslotDate =
        props.reScheduleData.appointmentDate !== undefined &&
          props.reScheduleData.appointmentDate !== ""
          ? props.reScheduleData.appointmentDate
          : "";
      ExBindtimeslotDate =
        props.reScheduleData.appointmentDate !== undefined &&
          props.reScheduleData.appointmentDate !== ""
          ? props.reScheduleData.appointmentDate
          : "";

      const StartingTime =
        props.reScheduleData.appointmentStartTime !== undefined &&
          props.reScheduleData.appointmentStartTime !== ""
          ? props.reScheduleData.appointmentStartTime
          : "";
      const EndingTime =
        props.reScheduleData.appointmentEndTime !== undefined &&
          props.reScheduleData.appointmentEndTime !== ""
          ? props.reScheduleData.appointmentEndTime
          : "";
      const slicedStartdate =
        StartingTime.slice(0, 5) + StartingTime.slice(8, StartingTime.length);

      const Selectedslot = slicedStartdate + "-" + EndingTime;
      const slotDuration =
        moment(StartingTime, "HH:mm").format("hh:mm") +
        "-" +
        moment(EndingTime, "HH:mm").format("hh:mm A");
      SetTime(slotDuration);

      SetContextStartDate(
        props.reScheduleData.appointmentStartTime !== undefined &&
          props.reScheduleData.appointmentStartTime !== ""
          ? props.reScheduleData.appointmentStartTime
          : ""
      );

      SetContextEndDate(
        props.reScheduleData.appointmentEndTime !== undefined &&
          props.reScheduleData.appointmentEndTime !== ""
          ? props.reScheduleData.appointmentEndTime
          : ""
      );

      // if(exchangeAgreementId){
      setStartDateExc(moment(ExBindtimeslotDate).format("YYYY-MM-DD"))
      // }
      // else{
      setStartDate(moment(BindtimeslotDate).format("YYYY-MM-DD"));
      // }

      SetCoworkers(
        props.reScheduleData.coWorkersRequired !== undefined &&
          props.reScheduleData.coWorkersRequired !== ""
          ? props.reScheduleData.coWorkersRequired
          : ""
      );
      SetCoworkersExc(
        props.reScheduleData.coWorkersRequired !== undefined &&
          props.reScheduleData.coWorkersRequired !== ""
          ? props.reScheduleData.coWorkersRequired
          : ""
      );
      SetAddressline1(
        props.reScheduleData.addressLine1 !== undefined &&
          props.reScheduleData.addressLine1 !== ""
          ? props.reScheduleData.addressLine1
          : ""
      );
      SetAddressline2(
        props.reScheduleData.addressLine2 !== undefined &&
          props.reScheduleData.addressLine2 !== ""
          ? props.reScheduleData.addressLine2
          : ""
      );
      SetZip(
        props.reScheduleData.zip !== undefined &&
          props.reScheduleData.zip !== ""
          ? props.reScheduleData.zip
          : ""
      );
      SetCity(
        props.reScheduleData.city !== undefined &&
          props.reScheduleData.city !== ""
          ? props.reScheduleData.city
          : ""
      );
      SetState(
        props.reScheduleData.state != undefined &&
          props.reScheduleData.state != ""
          ? props.reScheduleData.state
          : ""
      );
      SetInstructions(
        props.reScheduleData.instructions !== undefined &&
          props.reScheduleData.instructions !== ""
          ? props.reScheduleData.instructions
          : ""
      );
      SetExcInstructions(props.reScheduleData.instructions !== undefined &&
        props.reScheduleData.instructions !== ""
        ? props.reScheduleData.instructions
        : "")
      SetRescheduleEventId(
        props.reScheduleData.eventId !== undefined &&
          props.reScheduleData.eventId !== ""
          ? props.reScheduleData.eventId
          : ""
      );
      SetdeliveryStatus(
        props.reScheduleData.deliveryStatus !== undefined &&
          props.reScheduleData.deliveryStatus !== ""
          ? props.reScheduleData.deliveryStatus
          : ""
      );
      Settimeslotid(
        props.reScheduleData.timeSlotId != undefined &&
          props.reScheduleData.timeSlotId !== ""
          ? props.reScheduleData.timeSlotId
          : ""
      );
      SetExReturnTimeSlotId(
        props.reScheduleData.timeSlotId != undefined &&
          props.reScheduleData.timeSlotId !== ""
          ? props.reScheduleData.timeSlotId
          : "");
      SetIdAddress(
        props.reScheduleData.addressId != undefined &&
          props.reScheduleData.addressId !== ""
          ? props.reScheduleData.addressId
          : ""
      );
      if (
        props.reScheduleData.deliveryType != undefined &&
        props.reScheduleData.deliveryType !== ""
      ) {

        if (props.reScheduleData.deliveryType == "Delivery" || props.appiontmentType == 'P') {
          SetcheckDeliverytype("DEL");
          SetDeliverytype("D");
        }
        else if (props.reScheduleData.deliveryType == "Pickup") {
          SetcheckDeliverytypeExc("DEL");
          SetDeliverytypeExc("P")
        }
        else if (props.reScheduleData.deliveryType == "Carry In") {
          SetcheckDeliverytypeExc("CAR");
          SetDeliverytypeExc("CI")
        }
        else {
          SetcheckDeliverytype("CAR");
          SetDeliverytype("CO");
        }
      }
    } else {
      console.log("BindtimeslotId", BindtimeslotId);
      console.log("BindtimeslotDate", BindtimeslotDate);
      // setBindtimeslotId('');
      // (BindtimeslotId = ''),
      // BindtimeslotDate = '';
      SetRescheduleProps("Schedule");
      getCoworkerRequiredData();
      getStatedata();
      GetTimeSlot(new Date());
      GetTimeSlotExc(new Date());
      GetAddressMethod();
      getInventoryId();
      setmasterLoader(false);
      // eslint-disable-next-line no-console
      console.log(coWorkerRequiredArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    console.log(StatePopup);
    if (
      Line1Popup != "" &&
      CityPopup != "" &&
      ZipPopup != "" &&
      StatePopup !== "" &&
      StatePopup !== "0"
    ) {
      Setenableadd(false);
    } else {
      Setenableadd(true);
    }
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (
      ZipPopup !== undefined &&
      ZipPopup !== null &&
      ZipPopup != "" &&
      ZipPopup.length > 0
    ) {
      if (
        ZipPopup.length == 5 &&
        Line1Popup != "" &&
        CityPopup != "" &&
        StatePopup !== "" &&
        StatePopup !== "0"
      ) {
        Setenableadd(false);
      } else if (
        Line1Popup != "" &&
        CityPopup != "" &&
        StatePopup !== "" &&
        StatePopup !== "0" &&
        ZipPopup.length == 10
      ) {
        Setenableadd(false);
      } else {
        Setenableadd(true);
      }
    } else {
      Setenableadd(true);
    }
    console.log("eddfe4555", timeslotid);

    if (Deliverytype != "" && timeslotid != "") {
      console.log("dvsfb34", timeslotid);
      SetenableFinish(false);
    } else {
      console.log("2654edcw48wd");
      SetenableFinish(true);
    }
  }, [Line1Popup, ZipPopup, CityPopup, timeslotid, StatePopup, Deliverytype]);

  useEffect(
    () => {
      if (
        props.setItemSearchCompleted &&
        props.setAgrDetailsCompleted &&
        props.setInitialPaymentCompleted
      ) {
        props.setItemSearchCompleted(true);
        props.setAgrDetailsCompleted(true);
        props.setInitialPaymentCompleted(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // debugger
  useEffect(() => {
    console.log("ReSchedule Check", SaveTrigger);
    console.log("ReSchedule Called", props.isDeliveryReschedule);
    if (
      props !== undefined &&
      props.isDeliveryReschedule !== undefined &&
      props.isDeliveryReschedule.deliveryrescheduling != undefined &&
      props.isDeliveryReschedule.deliveryrescheduling === true &&
      SaveTrigger === false
    ) {
      console.log("ReSchedule Called", props.isDeliveryReschedule);
      Complete_delivery_status();
      // if(exchangeAgreementId || viewAgrData?.data?.agreementInformation?.isExchanged == 1 ){
      //   Complete_delivery_status_exchange();
      // }


    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  useEffect(() => {
    console.log("ViewAgreementData", viewAgrData);
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (
      viewAgrData.data != undefined &&
      viewAgrData.data != null &&
      viewAgrData.data.agreementWorkflow.isSwitchoutEnabled != undefined &&
      viewAgrData.data.agreementWorkflow.isSwitchoutEnabled != null
    ) {
      if (viewAgrData.data.agreementWorkflow.isSwitchoutEnabled == "1") {
        console.log(
          "isswitchoutenabled in delievry method",
          viewAgrData.data.agreementWorkflow.isSwitchoutEnabled
        );
        console.log("select other address", hideswitchoutbutton);
        sethideswitchoutbutton(false);
      }
    }
    if (viewAgrData?.data?.agreementCustomer) {
      const customerInfodata = viewAgrData.data?.agreementCustomer.filter(
        (el) => el.priority == "1"
      );
      if (customerInfodata.length > 0) {
        customerInfodata.map((value: any) => {
          setemailText(value.emailId);
          setphoneNo(value.phoneNumber);
          const customerName = value.firstName + " " + value.lastName;
          setCustomerName(customerName);
        });
      }
    }
    if (viewAgrData?.data?.agreementInformation) {
      setappointmentId(viewAgrData?.data?.agreementInformation?.appointmentId);
      const rmsInvInformation = viewAgrData?.data?.agreementInventories?.map(
        (el: any) => {
          return { inventoryNumber: el.inventoryNumber };
        }
      );
      setInventoryNumber(rmsInvInformation);
      setAgrNumber(viewAgrData?.data?.agreementInformation?.agreementNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAgrData]);

  useEffect(() => {
    if (exchangeAgreementId) {
      getAgreementInfoFn()
    }
  }, [])

  const getAgreementInfoFn = async () => {
    debugger
    const getAgreementInfoFnRes = await getAgreementInfo(exchangeAgreementId)
    if (getAgreementInfoFnRes.status == 200) {
      setagrInfoResp(getAgreementInfoFnRes?.data)
    }
  }

  console.log("entersdfghj", agrInfoResp)

  const getStatedata = async () => {
    // PropsDetails();
    const stateDropdown = await GetstateDropdown();
    if (stateDropdown.status === 200) {
      // eslint-disable-next-line no-console
      const buildConditionOption = stateDropdown.data;

      const setterCondition = [
        { stateAbbreviation: "Select" },

        ...buildConditionOption,
      ];
      console.log("STATESSSSS", setterCondition);
      SetStatedrop(setterCondition);
      SetStateconditionLoader(false);
    } else {
      const setterCondition = [{ stateAbbreviation: "Select" }];
      SetStatedrop(setterCondition);
      SetStateconditionLoader(false);
    }
  };

  const getCoworkerRequiredData = async () => {
    const bodyobject = {
      storeNumbers: [storeNumber],

      paramKeyNames: ["CoworkerRequired", "EnableGoogleAddressValidation"],
    };
    let CoworkerNum
    if (storeNumber && storeNumber != null && storeNumber != undefined && storeNumber != '' && storeNumber != 'null' && storeNumber != 'undefined') {
      CoworkerNum = await CoworkerRequired(bodyobject);
    }
    if (CoworkerNum.status === 200) {
      console.log(CoworkerNum.data, " CoworkerNum.data");
      const value =
        CoworkerNum.data !== null &&
          CoworkerNum.data.storeProfileResponse !== undefined &&
          CoworkerNum.data.storeProfileResponse &&
          CoworkerNum.data.storeProfileResponse.configDetails !== undefined &&
          CoworkerNum.data.storeProfileResponse.configDetails.length > 0 &&
          CoworkerNum.data.storeProfileResponse.configDetails[0].configDetails !==
          undefined &&
          CoworkerNum.data.storeProfileResponse.configDetails[0].configDetails
            .length > 0
          ? (CoworkerNum?.data?.storeProfileResponse?.configDetails[0]
            ?.configDetails.find(
              (config) => config.paramKeyName === 'CoworkerRequired'
            )).paramValue
          : 0;
      const exchangeResponse = CoworkerNum?.data?.storeProfileResponse?.configDetails[0]
        ?.configDetails.find(
          (config) => config.paramKeyName === 'EnableGoogleAddressValidation'
        );
      (exchangeResponse && exchangeResponse.paramValue == '1') && setCanEnableGoogleValdation(true)
      // eslint-disable-next-line no-console
      console.log(value, "value in worker");
      console.log(value.paramValue, "value.paramValue in worker");
      const integervalue = parseInt(value);
      const arrayNumber: any = [];

      for (let i = 1; i <= integervalue; i++) {
        arrayNumber.push(i);
      }
      // eslint-disable-next-line no-console
      console.log("ArrayNumber", arrayNumber);
      setcoWorkerRequiredArray(arrayNumber);
      SetconditionLoader(false);
    } else {
      console.log("enterswertyu")
      const setterCondition = ["Select"];
      setcoWorkerRequiredArray(setterCondition);
      SetconditionLoader(false);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const GetAddressMethod = async () => {
    const getdeliveryaddress = await GetDeliveryAddress(customerId);

    // eslint-disable-next-line no-console
    console.log(getdeliveryaddress.data, "address value");

    if (
      getdeliveryaddress.data !== undefined &&
      getdeliveryaddress.data.getAdd !== undefined &&
      getdeliveryaddress.data.getAdd !== null &&
      getdeliveryaddress.data.getAdd.length > 0
    ) {
      // eslint-disable-next-line no-console
      console.log(getdeliveryaddress.data, "Inside Log");
      SetGetAddress(getdeliveryaddress.data.getAdd);
      SetaddressId(getdeliveryaddress.data.getAdd[0].addressId);
      addRowArray = getdeliveryaddress.data.getAdd;
    }
    // eslint-disable-next-line no-console
    console.log(getdeliveryaddress.data, "testingresponsetimeslot");

    if (
      props == undefined ||
      props == null ||
      props == "" ||
      props.reScheduleData == undefined ||
      props.reScheduleData == null ||
      props.reScheduleData == ""
    ) {
      SetAddressline1(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].addressLine1
          : ""
      );
      SetAddressline2(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].addressLine2
          : ""
      );

      const Zipcode =
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].zip
          : "";

      let plus4Code = "";

      if (getdeliveryaddress.data.getAdd[0].plus4 != null) {
        plus4Code =
          getdeliveryaddress.data !== undefined &&
            getdeliveryaddress.data.getAdd !== undefined &&
            getdeliveryaddress.data.getAdd.length > 0
            ? getdeliveryaddress.data.getAdd[0].plus4
            : "";

        // const tempik = temps '-'

        SetZip(Zipcode + "-" + plus4Code);
      } else {
        SetZip(Zipcode);
      }

      SetCity(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].city
          : ""
      );
      SetState(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].stateProvinceName
          : ""
      );
      SetStatePopupId(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].stateProvinceId
          : ""
      );
      SetIdAddress(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].addressId
          : ""
      );
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const GetTimeSlot = async (StartingDate: any, ReSchdeuleSource?: any) => {
    //const startingdate = new Date(StartingDate);
    const startingdate = StartingDate;
    const maximumdate = moment(StartingDate).add(3, 'months').format('YYYY-MM-DD');
    setMaxDate(maximumdate);
    const endingdate = moment(StartingDate).add(9, "days").format("YYYY-MM-DD");
    //const endingdate = addDays(startingdate, 9);
    console.log(endingdate);
    console.log("startingdateuh", startingdate);
    console.log("changeDateFormatok", changeDateFormat(startingdate));
    const formattedstartingdate = moment(startingdate).format("YYYY-MM-DD");
    const formattedenddate = moment(endingdate).format("YYYY-MM-DD");
    // const formattedstartingdate = changeDateFormat(startingdate);
    // const formattedenddate = changeDateFormat(endingdate);
    console.log("DAtsssssssrefd", formattedenddate);
    // const bodyobj = {
    //   customerId: customerId,
    //   startDate: formattedstartingdate,
    //   endDate: formattedenddate,
    //   storeId: StoreId,
    //   eventSource: 'Store',
    // };
    const payload = {
      isLoaner: "Y",
      customerId: String(customerId),
      endDate: formattedenddate,
      eventSource: ReSchdeuleSource !== undefined ? ReSchdeuleSource : "STO",
      startDate: formattedstartingdate,
      storeId: window.sessionStorage.getItem("storeNumber"),
    };

    const timeslotresponse = await GetTimeSlots(payload);
    setTimeSlotYes(true);
    if (timeslotresponse.data !== undefined) {
      setAppoinmentSource(timeslotresponse.data.appointmentSource);
      // SetTimeslotReason(timeslotresponse);
      const TimeSlotsDetailsArrayValue: any = [];
      interface TimeSlotDetails {
        timeSlot: string;
        startTime: string;
        endTime: string;
        status: string;
      }

      interface TimeSlotDetailsArray {
        Date: string;
        TimeSlots: TimeSlotDetails;
      }

      const TimeSlotVariable = timeslotresponse.data.timeSlots;
      const sorted: any = Object.keys(TimeSlotVariable)
        .sort(function (a: any, b: any) {
          const date1: any = new Date(a);
          const date2: any = new Date(b);
          return date1 - date2;
        })
        .reduce((accumulator, key) => {
          accumulator[key] = TimeSlotVariable[key];

          return accumulator;
        }, {});
      Object.keys(sorted).forEach(function (key) {
        const value = sorted[key];
        //  console.log(key + ':' + value);
        const Timeslots: any = [];

        value.map((value: any) => {
          const timeslot_object = {} as TimeSlotDetails;
          timeslot_object.timeSlot = value.timeSlot;
          timeslot_object.startTime = value.startTime;
          timeslot_object.endTime = value.endTime;
          timeslot_object.status = value.status;
          Timeslots.push(timeslot_object);
        });

        const TotalTimeSlots = {} as TimeSlotDetailsArray;
        TotalTimeSlots.Date = key;
        TotalTimeSlots.TimeSlots = Timeslots;
        TimeSlotsDetailsArrayValue.push(TotalTimeSlots);
      });
      SettimeSlotData(TimeSlotsDetailsArrayValue);

      // SetTime('');
      // console.log('beoefevcdvbh', BindtimeslotId);
      // setBindtimeslotId('');
      // eslint-disable-next-line no-console
      console.log(TimeSlotsDetailsArrayValue, "timeslot converted array");
    } else {
      const StartingTime =
        props.reScheduleData.appointmentStartTime !== undefined &&
          props.reScheduleData.appointmentStartTime !== ""
          ? props.reScheduleData.appointmentStartTime
          : "";
      const EndingTime =
        props.reScheduleData.appointmentEndTime !== undefined &&
          props.reScheduleData.appointmentEndTime !== ""
          ? props.reScheduleData.appointmentEndTime
          : "";
      const slicedStartdate =
        StartingTime.slice(0, 5) + StartingTime.slice(8, StartingTime.length);

      const Selectedslot = slicedStartdate + "-" + EndingTime;
      const slotDuration =
        moment(StartingTime, "HH:mm").format("hh:mm") +
        " - " +
        moment(EndingTime, "HH:mm").format("hh:mm A");
      SetTime(slotDuration);
      //Timebind(event);
    }
    // eslint-disable-next-line no-console
    console.log(timeslotresponse.data, "testingresponsetimeslot");
  };

  const GetTimeSlotExc = async (StartingDate: any, ReSchdeuleSource?: any) => {
    //const startingdate = new Date(StartingDate);
    const startingdate = StartingDate;
    const maximumdate = moment(StartingDate).add(3, 'months').format('YYYY-MM-DD');
    setMaxDate(maximumdate);
    const endingdate = moment(StartingDate).add(9, "days").format("YYYY-MM-DD");
    //const endingdate = addDays(startingdate, 9);
    console.log(endingdate);
    console.log("startingdateuh", startingdate);
    console.log("changeDateFormatok", changeDateFormat(startingdate));
    const formattedstartingdate = moment(startingdate).format("YYYY-MM-DD");
    const formattedenddate = moment(endingdate).format("YYYY-MM-DD");
    // const formattedstartingdate = changeDateFormat(startingdate);
    // const formattedenddate = changeDateFormat(endingdate);
    console.log("DAtsssssssrefd", formattedenddate);
    // const bodyobj = {
    //   customerId: customerId,
    //   startDate: formattedstartingdate,
    //   endDate: formattedenddate,
    //   storeId: StoreId,
    //   eventSource: 'Store',
    // };
    const payload = {
      isLoaner: "Y",
      customerId: String(customerId),
      endDate: formattedenddate,
      eventSource: ReSchdeuleSource !== undefined ? ReSchdeuleSource : "STO",
      startDate: formattedstartingdate,
      storeId: window.sessionStorage.getItem("storeNumber"),
    };

    const timeslotresponse = await GetTimeSlots(payload);
    setTimeSlotYes(true);
    if (timeslotresponse.data !== undefined) {
      setAppoinmentSource(timeslotresponse.data.appointmentSource);
      // SetTimeslotReason(timeslotresponse);
      const TimeSlotsDetailsArrayValue: any = [];
      interface TimeSlotDetails {
        timeSlot: string;
        startTime: string;
        endTime: string;
        status: string;
      }

      interface TimeSlotDetailsArray {
        Date: string;
        TimeSlots: TimeSlotDetails;
      }

      const TimeSlotVariable = timeslotresponse.data.timeSlots;
      const sorted: any = Object.keys(TimeSlotVariable)
        .sort(function (a: any, b: any) {
          const date1: any = new Date(a);
          const date2: any = new Date(b);
          return date1 - date2;
        })
        .reduce((accumulator, key) => {
          accumulator[key] = TimeSlotVariable[key];

          return accumulator;
        }, {});
      Object.keys(sorted).forEach(function (key) {
        const value = sorted[key];
        //  console.log(key + ':' + value);
        const Timeslots: any = [];

        value.map((value: any) => {
          const timeslot_object = {} as TimeSlotDetails;
          timeslot_object.timeSlot = value.timeSlot;
          timeslot_object.startTime = value.startTime;
          timeslot_object.endTime = value.endTime;
          timeslot_object.status = value.status;
          Timeslots.push(timeslot_object);
        });

        const TotalTimeSlots = {} as TimeSlotDetailsArray;
        TotalTimeSlots.Date = key;
        TotalTimeSlots.TimeSlots = Timeslots;
        TimeSlotsDetailsArrayValue.push(TotalTimeSlots);
      });
      SettimeSlotDataExc(TimeSlotsDetailsArrayValue);

      // SetTime('');
      // console.log('beoefevcdvbh', BindtimeslotId);
      // setBindtimeslotId('');
      // eslint-disable-next-line no-console
      console.log(TimeSlotsDetailsArrayValue, "timeslot converted array");
    } else {
      const StartingTime =
        props.reScheduleData.appointmentStartTime !== undefined &&
          props.reScheduleData.appointmentStartTime !== ""
          ? props.reScheduleData.appointmentStartTime
          : "";
      const EndingTime =
        props.reScheduleData.appointmentEndTime !== undefined &&
          props.reScheduleData.appointmentEndTime !== ""
          ? props.reScheduleData.appointmentEndTime
          : "";
      const slicedStartdate =
        StartingTime.slice(0, 5) + StartingTime.slice(8, StartingTime.length);

      const Selectedslot = slicedStartdate + "-" + EndingTime;
      const slotDuration =
        moment(StartingTime, "HH:mm").format("hh:mm") +
        " - " +
        moment(EndingTime, "HH:mm").format("hh:mm A");
      SetTime(slotDuration);
      //Timebind(event);
    }
    // eslint-disable-next-line no-console
    console.log(timeslotresponse.data, "testingresponsetimeslot");
  };

  let itemInventorySTORE;
  const getInventoryId = async () => {
    //const agreementInfoResult = await getAgreementInfo(agreementId);
    itemInventorySTORE = viewAgrData?.data.agreementInventories[0].inventoryStoreNumber
    if (
      viewAgrData != undefined &&
      viewAgrData != null &&
      viewAgrData.data != undefined &&
      viewAgrData.data != null &&
      viewAgrData.data.agreementInventories[0] != undefined &&
      viewAgrData.data.agreementInventories[0] != null &&
      viewAgrData.data.agreementInventories[0].inventoryId != undefined &&
      viewAgrData.data.agreementInventories[0].inventoryId != null &&
      viewAgrData.data.agreementWorkflow != undefined &&
      viewAgrData.data.agreementWorkflow != null &&
      viewAgrData.data.agreementWorkflow.agreementNumber != undefined &&
      viewAgrData.data.agreementWorkflow.agreementNumber != null &&
      viewAgrData.data.agreementCustomer[0] != undefined &&
      viewAgrData.data.agreementCustomer[0] != null &&
      viewAgrData.data.agreementCustomer[0].globalCustomerId != undefined &&
      viewAgrData.data.agreementCustomer[0].globalCustomerId != null
    ) {
      const InventoryArray = viewAgrData.data.agreementInventories.map(
        (Obj: any) => {
          return Obj.inventoryId;
        }
      );
      console.log("InventoryArray", InventoryArray);
      Setinventoryid(InventoryArray);
      SetagreementNumber(viewAgrData.data.agreementWorkflow.agreementNumber);
      SetEpoProps({
        agreementNumber: viewAgrData.data.agreementWorkflow.agreementNumber,
      });
      SetGCID(viewAgrData.data.agreementCustomer[0].globalCustomerId);
      console.log(
        "GCID",
        viewAgrData.data.agreementCustomer[0].globalCustomerId
      );
    }
  };

  function GetDeliveryInformationMapper(
    agreementInformation: any,
    eventInformation: any
  ): any {
    if (agreementInformation != undefined && eventInformation != undefined) {
      const getDeliveryInformation: any = {};
      getDeliveryInformation.agreementId =
        agreementInformation.agreementInformation.agreementId;
      getDeliveryInformation.deliveryMethodStatus = deliveryMethodStatusDefine(
        eventInformation.appointmentInfo.appointmentType,
        eventInformation.appointmentInfo.appointmentStatusDesc,
        agreementInformation.agreementWorkflow.isSwitchoutEnabled,
        agreementInformation.agreementInformation.agreementStatusGroupCode,
        agreementInformation.agreementWorkflow.isDeliveryCompleted
      );
      getDeliveryInformation.deliveryInformation = {
        eventId: agreementInformation.agreementInformation.appointmentId,
        deliveryType: deliveryTypeDefine(
          eventInformation.appointmentInfo.appointmentType,
          eventInformation.appointmentInfo.appointmentTypeDesc,
          eventInformation.appointmentInfo.appointmentStatusDesc,
          agreementInformation.agreementWorkflow.isSwitchoutEnabled
        ),
        deliveryStatus: eventInformation.appointmentInfo.appointmentStatusDesc,
        addressId: eventInformation.customerInfo.address?.addressId,
        addressLine1: eventInformation.customerInfo.address?.addressLine1,
        addressLine2: eventInformation.customerInfo.address?.addressLine2,
        zip: eventInformation.customerInfo.address?.postalCode,
        zipPlus4: eventInformation.customerInfo.address?.postalCode,
        city: eventInformation.customerInfo.address?.city,
        state: eventInformation.customerInfo.address?.state,
        timeSlotId: eventInformation.timeSlotInfo.timeSlot,
        appointmentDate: eventInformation.appointmentInfo.appointmentDate,
        appointmentStartTime: eventInformation.timeSlotInfo.startTime,
        appointmentEndTime: eventInformation.timeSlotInfo.endTime,
        coWorkersRequired: eventInformation.appointmentInfo.requiredCoworkers,
        instructions: eventInformation.appointmentInfo.instructions,
      };
      return getDeliveryInformation;
    }
    return undefined;
  }

  function deliveryTypeDefine(
    appointmentType: string,
    appointmentTypeDesc: string,
    appointmentStatusDesc: string,
    switchout: string
  ): string {
    let appointmentTypeDescription: any = appointmentTypeDesc;
    if (["P", "CI"].includes(appointmentType)) {
      appointmentTypeDescription = "Return";
    }
    return appointmentTypeDescription;
  }

  function deliveryMethodStatusDefine(
    appointmentType: string,
    appointmentStatusDesc: string,
    switchout: string,
    agreementStatusGroup: string,
    isDeliveryCompleted: string
  ): string {
    //View Delivery Method
    let deliveryMethodStatus = "0";
    console.log(
      appointmentType,
      appointmentStatusDesc,
      switchout,
      agreementStatusGroup,
      isDeliveryCompleted
    );
    if (
      appointmentStatusDesc === "Completed" ||
      (["D", "CO"].includes(appointmentType) &&
        ["Completed"].includes(appointmentStatusDesc) &&
        isDeliveryCompleted === "1") ||
      (agreementStatusGroup == "CLOSED" &&
        appointmentStatusDesc == "Cancelled") ||
      ["SCI", "SCO", "SD", "S", "SP"].includes(appointmentType) ||
      (["P", "CI", "D", "CO"].includes(appointmentType) &&
        ["Completed", "Cancelled"].includes(appointmentStatusDesc) &&
        isDeliveryCompleted === "1")
    ) {
      deliveryMethodStatus = "1";
    }
    //View Delivery Method with No Appointment Records
    else if (["VOID", "CLOSED"].includes(agreementStatusGroup)) {
      deliveryMethodStatus = "2";
    }
    return deliveryMethodStatus;
  }

  // Disabling the delivery address Pop up, by clicking on Select Delivery Address.

  const disPopup = async () => {
    SetPopup(true);
    SetAddRow(getaddress);
    // SetenableRdo(true);
  };

  const hideDeliveryaddress = (event: any, isExchange?: any) => {
    debugger
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (isExchange) {
      console.log("enetrs the 1st")
      if (
        event?.target.value == "CarryinRdoExc" ||
        document.getElementById("carryout")
      ) {
        SetDeliverytypeExc("CI");
        if (props?.triggerdeliveryChanged) {
          props?.triggerdeliveryChanged()
        }
      } else {
        SetDeliverytypeExc("P");
        if (props?.triggerdeliveryChanged) {
          props?.triggerdeliveryChanged()
        }
      }
      SetcheckDeliverytypeExc("CAR");
      SetSaveTrigger(false);
    }
    else {
      console.log("enetrs the 2st")
      if (
        event?.target.value == "CarryoutRdo" ||
        document.getElementById("carryout")
      ) {
        SetDeliverytype("CO");
        if (props?.triggerdeliveryChanged) {
          props?.triggerdeliveryChanged()
        }
      } else {
        SetDeliverytype("D");
        if (props?.triggerdeliveryChanged) {
          props?.triggerdeliveryChanged()
        }
      }
      SetcheckDeliverytype("CAR");
      SetSaveTrigger(false);
    }

  };

  // Function for showing Delivery Address when Delivery is clicked again in Delivery Type heading

  const showDeliveryaddress = (event: any, isExchange?: any) => {
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (isExchange) {
      console.log("enetrs the 1st showdelivery")
      if (
        event?.target.value == "DeliveryRdoExc" ||
        document.getElementById("delivery")
      ) {
        SetDeliverytypeExc("P");
        if (props?.triggerdeliveryChanged) {
          props?.triggerdeliveryChanged()
        }
      } else {
        SetDeliverytypeExc("CI");
        if (props?.triggerdeliveryChanged) {
          props?.triggerdeliveryChanged()
        }
      }
      SetcheckDeliverytypeExc("DEL");
      SetSaveTrigger(false);
    }
    else {
      console.log("enetrs the 2st showdelivery")
      if (
        event?.target.value == "DeliveryRdo" ||
        document.getElementById("delivery")
      ) {
        SetDeliverytype("D");
        if (props?.triggerdeliveryChanged) {
          props?.triggerdeliveryChanged()
        }
      } else {
        SetDeliverytype("CO");
        if (props?.triggerdeliveryChanged) {
          props?.triggerdeliveryChanged()
        }
      }
      SetcheckDeliverytype("DEL");
      SetSaveTrigger(false);
    }
  };
  // console.log(Deliverytype);
  // Function for Cancel button and close image in Delivery Address Pop-Up

  const cancelbtn = () => {
    setmasterLoader(false);
    SetPopup(false);
  };

  const changeDateFormat = (date: Date) => {
    // eslint-disable-next-line prefer-const
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      // eslint-disable-next-line prefer-const
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  // Function for displaying selected and next five days
  const dates = async (selectedFormatdate: any, isExchange?: any) => {
    debugger;

    const validateYear = moment(selectedFormatdate).add(12, 'months').format('YYYY-MM-DD');
    const selectFormatdate = moment(selectedFormatdate).format('YYYY-MM-DD')
    if (isExchange) {
      if (selectFormatdate >= moment(new Date()).format('YYYY-MM-DD') && selectFormatdate < validateYear) {
        setdateError('')
        SettimeSlotDataExc([]);
        setStartDateExc(moment(selectedFormatdate).format('YYYY-MM-DD'));

        const startDateExc = selectedFormatdate;
        if (props.triggerdateChaged) {
          props.triggerdateChaged();
        }
        GetTimeSlotExc(startDateExc);
        setExReturnTime('');
        SetExReturnTimeSlotId('');
        console.log('beoefevcdvbh', BindtimeslotId);
        // setBindtimeslotId('');
        SetExReturnBindtimeslotId('')
      }
      else {
        setStartDateExc(moment(new Date()).format('YYYY-MM-DD'));
        setdateError('Date must not be in the past')
      }
    }
    else {
      if (selectFormatdate >= moment(new Date()).format('YYYY-MM-DD') && selectFormatdate < validateYear) {
        setdateError('')
        SettimeSlotData([]);
        setStartDate(moment(selectedFormatdate).format('YYYY-MM-DD'));
        // if (openReturnContent == false){
        //   console.log("openReturnContentsdfgh",openReturnContent)
        //   console.log("xcvbnm",selectedFormatdate)
        //   setStartDateExc(moment(selectedFormatdate).format('YYYY-MM-DD'));
        // }

        const startDate = selectedFormatdate;
        if (props.triggerdateChaged) {
          props.triggerdateChaged();
        }
        GetTimeSlot(startDate);
        SetTime('');
        Settimeslotid('');
        console.log('beoefevcdvbh', BindtimeslotId);
        setBindtimeslotId('');
        // SetExReturnBindtimeslotId('')
      }
      else {
        setStartDate(moment(new Date()).format('YYYY-MM-DD'));
        setdateError('Date must not be in the past')
      }
    }
  };

  const getslotdetails = (
    avail: any,
    modifiedStarttime: any,
    modifiedEndtime: any,
    isExchangeReturnFlow?: any
  ) => {
    debugger;
    if (props.triggertimeChanged !== undefined) {
      props.triggertimeChanged();
    }
    console.log("ContextStart", modifiedStarttime);
    console.log("ContextEnd", modifiedEndtime);
    SetContextStartDate(modifiedStarttime);
    SetContextEndDate(modifiedEndtime);
    if (isExchangeReturnFlow) {
      console.log("in the if isexchange")
      setExReturnTime(avail.target.value);
      SetExReturnBindtimeslotId(avail.target.id);
      SetExReturnTimeSlotId(avail.target.id);
      ExBindtimeslotDate = avail.target.name;
      setStartDateExc(moment(ExBindtimeslotDate).format("YYYY-MM-DD"));

      console.log("exReturnTimedfghj", exReturnTime)
    }
    else {
      console.log("in the else cond")
      SetTime(avail.target.value);
      Settimeslotid(avail.target.id);
      setBindtimeslotId(avail.target.id);
      BindtimeslotDate = avail.target.name;
      setStartDate(moment(BindtimeslotDate).format("YYYY-MM-DD"));
      console.log({ openReturnContent, exReturnBindtimeslotId, exReturnTime, exReturnTimeSlotId }, "sdfghjkvbn")
      // if (openReturnContent == false) {
      //   setExReturnTime(avail.target.value);
      //   SetExReturnBindtimeslotId(avail.target.id);
      //   SetExReturnTimeSlotId(avail.target.id);
      //   ExBindtimeslotDate = avail.target.name;
      //   BindtimeslotDate = avail.target.name
      //   setStartDateExc(moment(ExBindtimeslotDate).format("YYYY-MM-DD"));
      // }
      console.log("sdfghjcvbnm", BindtimeslotDate)
      console.log("sdfghjkvvvddwtttyybnm", ExBindtimeslotDate)
    }
    // Settimeslotid(avail.target.id);
    // setBindtimeslotId(avail.target.id);
    // BindtimeslotId = avail.target.id;
    buildTimeSlot();
    SetSaveTrigger(false);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const bindGrid = () => {
    if (addRow != undefined && addRow != null && addRow.length > 0) {
      // eslint-disable-next-line no-console
      console.log("addRow", addRow);
      // SetEnableLoaderButton(false);
      return addRow.map((val: any, index: any) => {
        const shtAddr1 =
          val.addressLine1 != null && val.addressLine1 != undefined
            ? val.addressLine1.length > 15
              ? val.addressLine1.substring(0, 25) + "..."
              : val.addressLine1
            : "";
        const shtAddr2 =
          val.addressLine2 != null && val.addressLine2 != undefined
            ? val.addressLine2.length > 15
              ? val.addressLine2.substring(0, 25) + "..."
              : val.addressLine2
            : "";

        let Zipandand4plus = "";
        // eslint-disable-next-line no-console
        console.log(val.zip);
        // eslint-disable-next-line no-console
        console.log(val.plus4);
        if (val.zip != undefined && val.zip != null) {
          if (val.plus4 != null && val.plus4 != undefined) {
            Zipandand4plus = val.zip + "-" + val.plus4;
          } else {
            Zipandand4plus = val.zip;
          }
        }

        const shtZip = Zipandand4plus;

        const shtCity =
          val.city != null && val.city != undefined
            ? val.city.length > 15
              ? val.city.substring(0, 25) + "..."
              : val.city
            : "";
        const shtState =
          val.stateProvinceName != null && val.city != undefined
            ? val.stateProvinceName.length > 15
              ? val.stateProvinceName.substring(0, 25) + "..."
              : val.stateProvinceName
            : "";

        console.log("addressId", val.addressId);

        return (
          // eslint-disable-next-line react/jsx-key
          <RACTableRow>
            <RACTableCell>
              <RACRadio
                value={val.addressId}
                id={index}
                name="flexRadioDefault"
                onClick={Selectaddress.bind(event)}
                checked={TEMP == index ? true : false}
                size={"small"}
              />
              <label
                title={val.addressLine1}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title={val.addressLine1}
                id={"addLin1Lab" + index}
              >
                {shtAddr1}
              </label>
            </RACTableCell>
            <RACTableCell>
              <label
                title={val.addressLine2}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title={val.addressLine2}
                id={"addLin2Lab" + index}
              >
                {shtAddr2}
              </label>
            </RACTableCell>
            <RACTableCell>
              <label
                title={val.zip_plus4}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title={val.zip_plus4}
                id={"addZipLab" + index}
              >
                {shtZip}
              </label>
            </RACTableCell>
            <RACTableCell>
              <label
                title={val.city}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title={val.city}
                id={"addCityLab" + index}
              >
                {shtCity}
              </label>
            </RACTableCell>
            <RACTableCell>
              <label
                title={val.stateProvinceName}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title={val.stateProvinceName}
                id={"addStateLab" + index}
              >
                {shtState}
              </label>
            </RACTableCell>
          </RACTableRow>
        );
      });
    } else {
      return (
        <div className={classes.Norecords}>
          <CircularProgress />
        </div>
      );
    }
  };

  const addadress = async (postaddress: any) => {
    const updatecustomerresponse = await UpdateAddressInfo(postaddress);
    // const updatecustomerresponse = await updatecustomer(postaddress);
    if (updatecustomerresponse.status === 400) {
      SetDisplayErrorMessage("No Records Found");
      setmasterLoader(false);
      SetErrorpopup(true);
    } else if (updatecustomerresponse.status === 500) {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      SetDisplayErrorMessage("Something Went Wrong");
      setmasterLoader(false);
      setValidateIsOpen(false);
      SetErrorpopup(true);
    }
    if (
      updatecustomerresponse !== undefined &&
      updatecustomerresponse.data !== undefined &&
      updatecustomerresponse.data.updateAddress != undefined &&
      updatecustomerresponse.data.updateAddress.value != undefined &&
      updatecustomerresponse.data.updateAddress.value.customerId != undefined &&
      updatecustomerresponse !== null
    ) {
      SetLine1Popup("");
      SetLine2Popup("");
      SetZipPopup("");
      SetCityPopup("");
      SetStatePopup("");

      const getdeliveryaddress = await GetDeliveryAddress(customerId);

      // eslint-disable-next-line no-console
      console.log(getdeliveryaddress.data, "address value");
      setmasterLoader(false);

      if (
        getdeliveryaddress.data !== undefined &&
        getdeliveryaddress.data.getAdd !== undefined &&
        getdeliveryaddress.data.getAdd !== null &&
        getdeliveryaddress.data.getAdd.length > 0
      ) {
        // eslint-disable-next-line no-console
        console.log(getdeliveryaddress.data, "Inside Log");
        const index: any = getdeliveryaddress.data.getAdd.findIndex(
          (x) =>
            x.addressLine1 == newDeliveryAddress.addresses[0].addressLine1 &&
            x.city == newDeliveryAddress.addresses[0].city &&
            x.stateProvinceName == newDeliveryAddress.addresses[0].state
        );
        SetTEMP(String(index));
        SetGetAddress(getdeliveryaddress.data.getAdd);
        addRowArray = getdeliveryaddress.data.getAdd;
        SetAddRow(addRowArray);
      }
    }
  };

  const addGridRow = async () => {
    debugger;
    setmasterLoader(true);

    const ZipPlus4Split: any = ZipPopup.replace("-", "");
    console.log(ZipPlus4Split);

    const addressPayload: any = {
      MessageID: "CMS006-93589345",
      MessageType: "CMS006",
      MessageDTS: "2014-05-23-14:30:15:254633",
      MessageDTSTZOffset: "-06:00",
      RegionID: "1",
      ClientID: "8",
      ClientLocationNumber: "00006",
      ClientSource: "1",
      ClientOriginator: "1",
      LocationNumber: storeNumber,
      EncryptionMethod: "0",
      Addresses: [
        {
          AddressID: "11114",
          StreetAddress: Line1Popup,
          City: CityPopup,
          StateProvince: StatePopup,
          PostalCode: ZipPlus4Split,
          Country: "USA",
        },
      ],
      StoreConfig: {
        storeNumbers: [storeNumber],
        paramKeyNames: ["AddressDoctorEnabled"],
      },
    };
    try {
      if (canEnableGoogleValdation) {
        const googleAddressValidationPayload: any = JSON.parse(JSON.stringify(addressPayload))
        googleAddressValidationPayload.enableGoogleAddressValidation = true;
        const arr = [Line1Popup, CityPopup, StatePopup, ZipPlus4Split]
        if (Line2Popup) {
          addressPayload.Addresses[0].StreetAddress += ` ${Line2Popup}`
          arr.unshift(Line2Popup)
        }
        const validateAddressPayload = {
          postalCode: ZipPlus4Split,
          addressLines: arr
        }
        googleAddressValidationPayload.validateAddress = validateAddressPayload
        const response: any = await Promise.all([GetSuggestedAddress(addressPayload), GetSuggestedAddress(googleAddressValidationPayload)]);
        debugger;
        if (response[0].status === 400 || response[1].status === 400) {
          SetDisplayErrorMessage("No Records Found");
          setmasterLoader(false);
          SetErrorpopup(true);
        } else if (response[0].status === 500 || response[1].status === 500) {
          // SetDisplayErrorMessage('Something Went Wrong');
          setmasterLoader(false);
          setValidateIsOpen(true);
          setSuggestedAddress(undefined);
          setAddressErrorMsg(
            "Automatic Address validation is Unavailable for the store # Please validate the address Manually."
          );
          // SetErrorpopup(true);
        }
        else if (response[0].status == 200 && response[1].status == 200 && response[0].data && response[1].data) {
          setmasterLoader(false);
          interface AddressResponse {
            message?: string;
            status: boolean;
            matchCode?: string | undefined;
            suggestedAddress?: string;
            postalAddress?: PostalAddress;

          }

          interface PostalAddress {
            addressLines: string[];
            administrativeArea: string;
            postalCode: string;
            locality: string;
          }
          const result: AddressResponse = validateAddressFn(response[1].data.validateAddress, response[0].data.validateAddress,
            (response[0].data && response[0].data.storeConfig && response[0].data.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == '1') ? true : false, googleAddressValidationPayload);
          console.log(result, "te result from the validatAddress fn");

          if (result.status) {
            if (result.matchCode) {
              setmasterLoader(false);
              setValidateIsOpen(true);
              setSuggestedAddress(undefined);
              const errorMessage: string =
                result.matchCode == '1'
                  ? `Address could not be corrected. Unlikely to match. Do you want to override?`
                  : result.matchCode == '2'
                    ? `Zipcode found: ${ZipPlus4Split}. Do you want to override?`
                    : '';
              setAddressErrorMsg(
                errorMessage
              );
            }
            else {
              if (result?.postalAddress) {
                setdefaultSuggestedAddress('GA')
                setCanSuggestAddress(true)
                setValidatedAddress(result)
                setSuggestedAddress({
                  Addresses: {
                    PostalCode: result.postalAddress.postalCode,
                    City: result.postalAddress.locality,
                    StateProvince: result.postalAddress.administrativeArea,
                    StreetAddress: result.postalAddress.addressLines.length > 1 ? result.postalAddress.addressLines[1] : result.postalAddress.addressLines[0]
                  }
                })
              }
            }

          }
          else {
            setCantSuggestAddress(true)
            setValidatedAddress(result)
          }

        }
        else {
          throw 'Something went wrong'
        }
      }
      else {
        const response: any = await GetSuggestedAddress(addressPayload);
        console.log("AddressDoctor", response);
        debugger;
        if (response.status === 400) {
          SetDisplayErrorMessage("No Records Found");
          setmasterLoader(false);
          SetErrorpopup(true);
        } else if (response.status === 500) {
          // SetDisplayErrorMessage('Something Went Wrong');
          setmasterLoader(false);
          setValidateIsOpen(true);
          setSuggestedAddress(undefined);
          setAddressErrorMsg(
            "Automatic Address validation is Unavailable for the store # Please validate the address Manually."
          );
          // SetErrorpopup(true);
        }
        if (
          response.data !== undefined &&
          response.data !== null &&
          response.data.storeConfig !== undefined &&
          (response.data.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == "Y" ||
            response.data.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == "1" ||
            response.data.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == 1)
        ) {
          debugger;
          setSuggestedAddress(response.data.validateAddress);
          // const ZipValue = response.validateAddress.Addresses.PostalCode;
          // if (ZipValue.length < 10) {
          //   const values = ZipValue.replace('-', '');
          //   SetTrimmedZip(values);
          // }

          setmasterLoader(false);
          setValidateIsOpen(true);
        }
        else {
          setmasterLoader(false);
          setValidateIsOpen(true);
          setSuggestedAddress(undefined);
          setAddressErrorMsg(
            "Automatic Address validation is Unavailable for the store # Please validate the address Manually."
          );
        }

      }
    } catch (e: any) {
      console.log("Error In Address Doctor", e);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChange = (event: any) => {
    if (event.target.value !== undefined) {
      // eslint-disable-next-line no-console
      console.log("Inside", event.target.name);
      let regexItem;
      if (event.target.name !== "state") {
        if (event.target.name == "city") {
          regexItem = new RegExp("^[a-zA-Z]*$");
        } else if (event.target.name == "zip") {
          regexItem = new RegExp("^[0-9 -]+$");
        }

        if (event.target.value.match(regexItem) || event.target.value == "") {
          console.log(event.target.value);
          // if (regexItem.test(event.target.value)) {
          if (event.target.name == "addressLine1") {
            SetLine1Popup(event.target.value);
          } else if (event.target.name == "addressLine2") {
            SetLine2Popup(event.target.value);
          } else if (event.target.name == "city") {
            // eslint-disable-next-line no-console
            console.log("Inside cisty value", event.target.value);
            SetCityPopup(event.target.value);
          } else if (event.target.name == "state") {
            SetStatePopup(event.target.value);
            SetStatePopupId(event.target.id);
          } else if (event.target.name == "zip") {
            const value = event.target.value.replace("-", "");
            console.log(value);
            if (value.length > 5) {
              SetZipPopup(
                value.trim().substring(0, 5) +
                "-" +
                value.trim().substring(5, value.length)
              );
            } else {
              SetZipPopup(value);
            }
          }
        }
      }
    }
  };

  const Selectaddress = (event: any) => {
    /* eslint-disable no-console */
    console.log(event);
    /* eslint-disable no-console */
    console.log(event.target.id);
    console.log("TEMP 1", TEMP);
    SetTEMP(event.target.id);
    SetIdAddress(event.target.value);
    console.log("vhdjsjjdfjdfh", event.target.value);

    console.log("TEMP 2", TEMP);
    const temporary = TEMP;
    console.log("Var TEMP", temporary);
  };

  const Okpopup = () => {
    setmasterLoader(false);
    /* eslint-disable no-console */
    console.log("TEMP 3", TEMP);

    /* eslint-disable no-console */
    console.log("ArrayValue", addRowArray);
    /* eslint-disable no-console */
    console.log("array size", TEMP);
    SetAddressline1(addRowArray[TEMP].addressLine1);
    SetAddressline2(addRowArray[TEMP].addressLine2);

    if (addRowArray[TEMP].zip != null && addRowArray[TEMP].zip != undefined) {
      if (
        addRowArray[TEMP].plus4 != null &&
        addRowArray[TEMP].zip != undefined
      ) {
        SetZip(addRowArray[TEMP].zip + "-" + addRowArray[TEMP].plus4);
      } else {
        SetZip(addRowArray[TEMP].zip);
      }
    }
    SetCity(addRowArray[TEMP].city);
    SetState(addRowArray[TEMP].stateProvinceName);
    Setagreementid(IdAddress);
    SetPopup(false);
    SetSaveTrigger(false);
  };

  const Loadstate = () => {
    if (Statedrop !== undefined) {
      return Statedrop.map((entry: any) => {
        return {
          label: entry.stateAbbreviation,
          value: entry.stateAbbreviation,
        };
      });
    } else {
      return [{ label: "Select", value: "0" }];
    }
  };

  const RadioBtnclick = (type: any) => {
    if (type === "SUG") {
      setBtnIsdisabled1(true);

      setBtnIsdisabled2(false);

      setdefaultSuggestedAddress("SUG");
    } else if (type === "ENT") {
      setBtnIsdisabled1(false);

      setBtnIsdisabled2(true);

      setdefaultSuggestedAddress("ENT");
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const continueclick = async (type: any) => {
    setCanSuggestAddress(false)
    setValidateIsOpen(false);
    setmasterLoader(true);

    // const getGcidresponse = await Getglobalcustomerid(customerId);
    // // SetEnableLoaderButton(true);
    // // eslint-disable-next-line no-console
    // console.log(
    //   'gcid response',
    //   getGcidresponse !== undefined &&
    //     getGcidresponse.data &&
    //     getGcidresponse.data.globalCustomerId !== undefined &&
    //     getGcidresponse.data.globalCustomerId !== null
    //     ? getGcidresponse.data.globalCustomerId
    //     : ''
    // );

    if (type == "SUG" || type == 'GA') {
      const ZipAddress = SuggestedAddress.Addresses.PostalCode;

      let postAddresszip = "";

      if (ZipAddress.length == 10) {
        postAddresszip = ZipAddress.slice(0, 5) + "-" + ZipAddress.slice(6, 10);
      } else if (ZipAddress.length >= 5) {
        postAddresszip = ZipAddress.slice(0, 5);
      }

      const postaddress = {
        customerId: customerId,
        addresses: [
          {
            addressType: "DELIV",
            active: "Y",
            addressLine1: SuggestedAddress.Addresses.StreetAddress,
            addressLine2: Line2Popup,
            city: SuggestedAddress.Addresses.City,
            state: SuggestedAddress.Addresses.StateProvince,
            postalCode: postAddresszip,
            agreementIds: [agreementId],
          },
        ],
      };
      // eslint-disable-next-line no-console
      console.log("after api ", postaddress);
      if (postaddress != undefined) {
        addadress(postaddress);
        newDeliveryAddress = postaddress;
        setNewDeliverAddress(newDeliveryAddress);
        // SetenableRdo(false);
      } else {
        return (
          <div className={classes.Norecords}>
            <CircularProgress />
          </div>
        );
      }
    } else if (type == "ENT") {
      let postAddresszip = "";
      if (ZipPopup.length == 10) {
        postAddresszip = ZipPopup.slice(0, 5) + "-" + ZipPopup.slice(6, 10);
      } else if (ZipPopup.length >= 5) {
        postAddresszip = ZipPopup.slice(0, 5);
      }

      const postaddress = {
        customerId: customerId,
        addresses: [
          {
            addressType: "DELIV",
            active: "Y",
            addressLine1: Line1Popup,
            addressLine2: Line2Popup,
            city: CityPopup,
            state: StatePopup,
            postalCode: postAddresszip,
            agreementIds: [agreementId],
          },
        ],
      };
      // eslint-disable-next-line no-console
      console.log("after api ", postaddress);
      if (postaddress != undefined) {
        addadress(postaddress);
        newDeliveryAddress = postaddress;
        setNewDeliverAddress(newDeliveryAddress);
        // SetenableRdo(false);
      } else {
        return (
          <div className={classes.Norecords}>
            <CircularProgress />
          </div>
        );
      }
    }
  };
  const getnextamountdue = async () => {
    const paymentobject = {
      agreementIdCustomerId: [
        {
          agreementId: parseInt(agreementId),
        },
      ],
    };

    const getamountresponse = await getnextpaymentamount(paymentobject);

    if (getamountresponse.status === 504) {
      SetErrorpopup(true);
    } else {
      if (
        getamountresponse.data !== undefined &&
        getamountresponse.data !== null &&
        getamountresponse.data.amountDueResponse !== undefined &&
        getamountresponse.data.amountDueResponse !== null
      ) {
        const AmountResponse = getamountresponse.data.amountDueResponse.find(
          (x: { agreementId: string }) => x.agreementId == agreementId
        );
        /* eslint-disable no-console */
        console.log("agreementid related data", AmountResponse);

        if (
          AmountResponse !== undefined &&
          AmountResponse !== null &&
          AmountResponse !== "" &&
          AmountResponse.ldwAmount !== undefined &&
          AmountResponse.ldwAmount !== null
        ) {
          const Totalamountdue =
            parseFloat(AmountResponse.amountDue) +
            parseFloat(AmountResponse.ldwAmount);
          /* eslint-disable no-console */
          console.log("agreementid Total Amount", Totalamountdue);
          setnextpaymentinfo({
            ...nextpaymentinfo,
            nextamountdue: Totalamountdue.toFixed(2),
            nextduedate: AmountResponse.nextDueDate,
          });
          console.log("next amount due", nextpaymentinfo);
          return Totalamountdue.toFixed(2);
        } else {
          /* eslint-disable no-console */
          console.log("nextpaymentamount", getamountresponse.data);

          setnextpaymentinfo({
            ...nextpaymentinfo,
            nextamountdue:
              getamountresponse.data.amountDueResponse[0].amountDue,
            nextduedate:
              getamountresponse.data.amountDueResponse[0].nextDueDate,
          });
          console.log("next amount due", nextpaymentinfo);
          console.log(
            "getamountdue response",
            getamountresponse.data.amountDueResponse[0].amountDue
          );
          return getamountresponse.data.amountDueResponse[0].amountDue;
        }
      }
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const receiptstatus = async (nextamount: any) => {
    console.log("view params for nextamount", nextamount);
    await LoadDeliveryreceipt(agreementId);
    // if (
    //   deliveryreceiptresponse !== undefined &&
    //   deliveryreceiptresponse.responseData !== undefined
    // ) {
    //   const TempArr: any = [];
    //   for (
    //     let i = 0;
    //     i <= deliveryreceiptresponse.responseData.length - 1;
    //     i++
    //   ) {
    //     const obj = {
    //       inventoryId: deliveryreceiptresponse.responseData[i].inventoryId,
    //       inventoryNumber:
    //         deliveryreceiptresponse.responseData[i].inventoryNumber,
    //       serialNumber: deliveryreceiptresponse.responseData[i].serialNumber,
    //       inventoryDesc: deliveryreceiptresponse.responseData[i].inventoryDesc,
    //     };
    //     TempArr.push(obj);
    //   }
    //   const bodyobj = {
    //     inventorydescription: TempArr,
    //     customerName:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].customerName
    //         : "",
    //     addressLine1:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].addressLine1
    //         : "",
    //     addressLine2:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0 &&
    //         deliveryreceiptresponse.responseData[0].addressLine2 !== null &&
    //         deliveryreceiptresponse.responseData[0].addressLine2 !== ""
    //         ? deliveryreceiptresponse.responseData[0].addressLine2
    //         : "",
    //     customerCity:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].customerCity
    //         : "",
    //     customerZip:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0 &&
    //         deliveryreceiptresponse.responseData[0].customerZip !== null &&
    //         deliveryreceiptresponse.responseData[0].customerPlus4 !== null
    //         ? deliveryreceiptresponse.responseData[0].customerZip +
    //         "-" +
    //         deliveryreceiptresponse.responseData[0].customerPlus4
    //         : deliveryreceiptresponse.responseData[0].customerZip,
    //     customerState:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].customerState
    //         : "",
    //     customerPhoneNumber:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].customerPhoneNumber
    //         : "",
    //     storeName:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].storeName
    //         : "",
    //     storeNumber:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].storeNumber
    //         : "",
    //     storeAddressLine1:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].storeAddressLine1
    //         : "",
    //     storeAddressLine2:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0 &&
    //         deliveryreceiptresponse.responseData[0].storeAddressLine2 !== null &&
    //         deliveryreceiptresponse.responseData[0].storeAddressLine2 !== ""
    //         ? deliveryreceiptresponse.responseData[0].storeAddressLine2
    //         : "",
    //     storeCity:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].storeCity
    //         : "",
    //     storeState:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].storeState
    //         : "",
    //     storeZip:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].storeZip
    //         : "",
    //     storePhoneNumber:
    //       deliveryreceiptresponse !== undefined &&
    //         deliveryreceiptresponse.responseData !== undefined &&
    //         deliveryreceiptresponse.responseData.length > 0
    //         ? deliveryreceiptresponse.responseData[0].storePhoneNumber
    //         : "",
    //     agreementId: Number(agreementId),
    //     customerId: customerId,
    //     deliveryDate: startDate,
    //     deliveryTime: Time,
    //     agreementNumber: agreementNumber,
    //     nexDueAmount:
    //       nextamount !== undefined && nextamount !== null
    //         ? nextamount.toString()
    //         : "",
    //   };

    //   console.log("view payload", bodyobj);

    //   const receiptresponse = await GenerateReceipt(bodyobj);

    //   if (receiptresponse.data !== undefined && receiptresponse.data !== null) {
    //     setpdfresponse(receiptresponse.data);
    //     console.log("delievry checklist", receiptresponse.data);
    //   }
    // }
  };

  const decideappointmentType = () => {
    return Deliverytype == "D" ? "P" : "CI";
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const Complete_delivery_status = async (isExchange?: any) => {
    if (RescheduleEventId != null && RescheduleEventId != "") {
      setmasterLoader(true);
      let NumberofCoworkers = "";
      if (Coworkers == "") {
        NumberofCoworkers = "1";
      } else {
        NumberofCoworkers = Coworkers;
      }
      let exchangeResponse

      if (deliveryStatus == "Cancelled") {
        const value = {
          agreementId: "98989898",
          isLoaner: "Y",
          loanerAction: {
            appointmentId: String(RescheduleEventId),
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            actionName: "Reopen",
            coWorkerId: employeeId,
          },
        };
        const result = await Confirmdelivery(value);
        console.log("result", result);
      }
      const complete = {
        storeNumber: storeNumber,
        //address_id: IdAddress,
        eventInformation: {
          partyId: customerId,
          //agreementId: agreementId,
          inventoryIds: inventoryid,
          type: props.isReturn === true ? "Return" : Deliverytype,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          eventDate: moment(startDate).format("MM/DD/YYYY"),
          eventSource: "Store",
          //eventId: RescheduleEventId,
          timeSlot: {
            timeSlotId: timeslotid,
          },
          eventInstruction: Instructions,
          requiredCoworkers: NumberofCoworkers ? NumberofCoworkers : '1',
          racdbSourced: true,
          //agreementNumber: AgrNumber,
          //customerName: cusName,
          isLoaner: "Y",
          loanerCreate: {
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            timeSlot: timeslotid,
            customerId: String(customerId),
            agreementNumber: AgrNumber,
            inventories: InventoryNumber,
            // coworkerId: "654644",
            appointmentId: String(RescheduleEventId),
            appointmentInfo: {
              appointmentType: !props.isReturn
                ? Deliverytype
                : decideappointmentType(),
              appointmentSource: AppoinmentSource,
              appointmentDate: startDate,
              requiredCoworkers: NumberofCoworkers,
              instructions: Instructions,
            },
          },
        },
      };

      // eslint-disable-next-line sonarjs/no-duplicate-string
      console.log("Complete event reuest", complete);
      setmasterLoader(true)
      const Apiresult = await FinishdeliveryStatus(complete);
      setmasterLoader(false)

      if (isExchange && exReturnTimeSlotId) {
        setmasterLoader(true);
        exchangeResponse = await Complete_delivery_status_exchange()
        setmasterLoader(false);
      }
      console.log("Apiresult logg", Apiresult)
      /* eslint-disable no-console */
      try {
        console.log(Apiresult.data);
        setmasterLoader(true)
        const nextamount = await getnextamountdue();
        console.log("1731", nextamount);
        await receiptstatus(nextamount);
        setmasterLoader(false)
        // setmasterLoader(false);
        SetSaveTrigger(true);
        // exchange changes
        if (isExchange && exReturnTimeSlotId) {
          if (Apiresult.status == 200 && exchangeResponse.status == 200) {
            const ApiData = Apiresult.data;
            console.log("enter the open popup")
            setmasterLoader(false);
            SetOpenreturnconfirmpopup(true);
            setNewAppointmentId(ApiData?.appointmentInfo?.appointmentId);
            GetTimeSlot(startDate, AppoinmentSource);
            let TrimmedZip = "";
            let TrimmedPlus4: any = "";
            if (Zip.length > 5) {
              TrimmedZip = Zip.slice(0, 5);
              TrimmedPlus4 = Zip.slice(6, Zip.length);
            } else {
              TrimmedZip = Zip;
            }
            const Value = {
              eventId: RescheduleEventId,
              deliveryType: Deliverytype,
              addressId: IdAddress,
              addressLine1: Addressline1,
              addressLine2: Addressline2,
              zip: TrimmedZip,
              zipPlus4: TrimmedPlus4 != "" ? TrimmedPlus4 : null,
              city: City,
              state: State,
              timeSlotId: timeslotid,
              appointmentDate: moment(startDate).format("MM/DD/YYYY"),
              appointmentStartTime: ContextStartDate,
              appointmentEndTime: ContextEndDate,
              coWorkersRequired: Coworkers,
              instructions: Instructions,
            };
            console.log("Context", Value);

            const some = {
              deliveryMethodStatus: "0",
              deliveryInformation: Value,
            };
            console.log("DeliveryValue", some);
            setdeliveryMethodData(some);
          }
          else if (Apiresult.status == 200 && openReturnContent == false) {
            const ApiData = Apiresult.data;
            console.log("enter the open popup")
            setmasterLoader(false);
            SetOpendeliveryconfirmpopup(true);
            setNewAppointmentId(ApiData?.appointmentInfo?.appointmentId);
            GetTimeSlot(startDate, AppoinmentSource);
            let TrimmedZip = "";
            let TrimmedPlus4: any = "";
            if (Zip.length > 5) {
              TrimmedZip = Zip.slice(0, 5);
              TrimmedPlus4 = Zip.slice(6, Zip.length);
            } else {
              TrimmedZip = Zip;
            }
            const Value = {
              eventId: RescheduleEventId,
              deliveryType: Deliverytype,
              addressId: IdAddress,
              addressLine1: Addressline1,
              addressLine2: Addressline2,
              zip: TrimmedZip,
              zipPlus4: TrimmedPlus4 != "" ? TrimmedPlus4 : null,
              city: City,
              state: State,
              timeSlotId: timeslotid,
              appointmentDate: moment(startDate).format("MM/DD/YYYY"),
              appointmentStartTime: ContextStartDate,
              appointmentEndTime: ContextEndDate,
              coWorkersRequired: Coworkers,
              instructions: Instructions,
            };
            console.log("Context", Value);

            const some = {
              deliveryMethodStatus: "0",
              deliveryInformation: Value,
            };
            console.log("DeliveryValue", some);
            setdeliveryMethodData(some);
          }
          else if (Apiresult.status == 400 || exchangeResponse.status == 400) {
            setmasterLoader(false);
            SetErrorpopup(true);
            SetDisplayErrorMessage("Something Went Wrong");
          }
          /* eslint-disable sonarjs/no-duplicated-branches */
          else {
            setmasterLoader(false);
            SetErrorpopup(true);
            SetDisplayErrorMessage("Something Went Wrong");
          }
        }
        else {
          if (Apiresult.status == 200) {
            const ApiData = Apiresult.data;
            setmasterLoader(false);
            SetOpendeliveryconfirmpopup(true);
            setNewAppointmentId(ApiData?.appointmentInfo?.appointmentId);
            GetTimeSlot(startDate, AppoinmentSource);
            let TrimmedZip = "";
            let TrimmedPlus4: any = "";
            if (Zip.length > 5) {
              TrimmedZip = Zip.slice(0, 5);
              TrimmedPlus4 = Zip.slice(6, Zip.length);
            } else {
              TrimmedZip = Zip;
            }
            const Value = {
              eventId: RescheduleEventId,
              deliveryType: Deliverytype,
              addressId: IdAddress,
              addressLine1: Addressline1,
              addressLine2: Addressline2,
              zip: TrimmedZip,
              zipPlus4: TrimmedPlus4 != "" ? TrimmedPlus4 : null,
              city: City,
              state: State,
              timeSlotId: timeslotid,
              appointmentDate: moment(startDate).format("MM/DD/YYYY"),
              appointmentStartTime: ContextStartDate,
              appointmentEndTime: ContextEndDate,
              coWorkersRequired: Coworkers,
              instructions: Instructions,
            };
            console.log("Context", Value);

            const some = {
              deliveryMethodStatus: "0",
              deliveryInformation: Value,
            };
            console.log("DeliveryValue", some);
            setdeliveryMethodData(some);
          }
          else {
            setmasterLoader(false);
            SetErrorpopup(true);
            SetDisplayErrorMessage("Something Went Wrong");
          }
        }
      } catch (e) {
        setmasterLoader(false);
        SetErrorpopup(true);
        SetDisplayErrorMessage("Something Went Wrong");
      }
    }
    else {  // NOT reschedule 
      try {
        setmasterLoader(true);
        let NumberofCoworkers = "";
        if (Coworkers == "") {
          NumberofCoworkers = "1";
        } else {
          NumberofCoworkers = Coworkers;
        }
        let exchangeResponse

        const complete = {
          storeNumber: storeNumber,
          address_id: IdAddress,
          eventInformation: {
            partyId: customerId,
            agreementId: agreementId,
            inventoryIds: inventoryid,
            type: props.isReturn === true ? "Return" : Deliverytype,
            eventDate: moment(startDate).format("MM/DD/YYYY"),
            eventSource: "Store",
            timeSlot: {
              timeSlotId: timeslotid,
            },
            eventInstruction: Instructions,
            requiredCoworkers: NumberofCoworkers ? NumberofCoworkers : '1',
            racdbSourced: true,
            agreementNumber: AgrNumber,
            customerName: cusName,
            isLoaner: "Y",
            loanerCreate: {
              storeNumber: window.sessionStorage.getItem("storeNumber"),
              timeSlot: timeslotid,
              customerId: String(customerId),
              agreementNumber: AgrNumber,
              inventories: InventoryNumber,
              // coworkerId: "654644",
              appointmentInfo: {
                appointmentType: !props.isReturn
                  ? Deliverytype
                  : decideappointmentType(),
                appointmentSource: AppoinmentSource,
                appointmentDate: startDate,
                requiredCoworkers: NumberofCoworkers,
                instructions: Instructions,
              },
            },
          },
        };

        console.log("Complete event reuest", complete);
        setmasterLoader(true);
        const Apiresult = await FinishdeliveryStatus(complete);
        /* eslint-disable no-console */
        console.log(Apiresult.data);
        

        await createStorePickupEvent(Apiresult, NumberofCoworkers, props.isReturn );
        setmasterLoader(false);


        SetSaveTrigger(true);
        if (isExchange && exReturnTimeSlotId) {
          setmasterLoader(true);
          exchangeResponse = await Complete_delivery_status_exchange()
          setmasterLoader(false);
        }
        if (isExchange && exReturnTimeSlotId) {
          if (Apiresult.status == 200 && exchangeResponse.status == 200) {
            const ApiData = Apiresult.data;
            setmasterLoader(false);
            setNewAppointmentId(ApiData?.appointmentInfo?.appointmentId);
            SetOpenreturnconfirmpopup(true);
            /* eslint-disable sonarjs/no-duplicate-string */
            const request = {
              storeNumber: window.sessionStorage.getItem("storeNumber"),
              eventInformation: {
                partyId: "33925768",
                agreementId: agreementId,
                inventoryIds: ["9999207382277"],
                type: "Delivery",
                eventDate: "11/23/2021",
                eventSource: "Store",
                timeSlot: {
                  timeSlotId: "9999100000392",
                },
                eventInstructions: "created customer event",
                requiredCoworkers: "2",
                racdbSourced: true,
                isLoaner: "N",
              },
              address_id: addressId,
            };
            setmasterLoader(true)
            const result = await FinishdeliveryStatus(request);
            setmasterLoader(false)
          }
          else if (Apiresult.status == 200 && openReturnContent == false) {
            const ApiData = Apiresult.data;
            setmasterLoader(false);
            setNewAppointmentId(ApiData?.appointmentInfo?.appointmentId);
            SetOpendeliveryconfirmpopup(true);
            /* eslint-disable sonarjs/no-duplicate-string */
            const request = {
              storeNumber: window.sessionStorage.getItem("storeNumber"),
              eventInformation: {
                partyId: "33925768",
                agreementId: agreementId,
                inventoryIds: ["9999207382277"],
                type: "Delivery",
                eventDate: "11/23/2021",
                eventSource: "Store",
                timeSlot: {
                  timeSlotId: "9999100000392",
                },
                eventInstructions: "created customer event",
                requiredCoworkers: "2",
                racdbSourced: true,
                isLoaner: "N",
              },
              address_id: addressId,
            };
            setmasterLoader(true)
            const result = await FinishdeliveryStatus(request);
            setmasterLoader(false)
          }
          else if (Apiresult.status == 400 || exchangeResponse.status == 400) {
            setmasterLoader(false);
            SetErrorpopup(true);
            SetDisplayErrorMessage("Something Went Wrong");
          }
          /* eslint-disable sonarjs/no-duplicated-branches */
          else {
            setmasterLoader(false);
            SetErrorpopup(true);
            SetDisplayErrorMessage("Something Went Wrong");
          }
        }
        else {
          if (Apiresult.status == 200) {
            const ApiData = Apiresult.data;
            setmasterLoader(false);
            setNewAppointmentId(ApiData?.appointmentInfo?.appointmentId);
            SetOpendeliveryconfirmpopup(true);
            const request = {
              storeNumber: window.sessionStorage.getItem("storeNumber"),
              eventInformation: {
                partyId: "33925768",
                agreementId: agreementId,
                inventoryIds: ["9999207382277"],
                type: "Delivery",
                eventDate: "11/23/2021",
                eventSource: "Store",
                timeSlot: {
                  timeSlotId: "9999100000392",
                },
                eventInstructions: "created customer event",
                requiredCoworkers: "2",
                racdbSourced: true,
                isLoaner: "N",
              },
              address_id: addressId,
            };
            setmasterLoader(true)
            const result = await FinishdeliveryStatus(request);
            setmasterLoader(false)
          } else {
            setmasterLoader(false);
            SetErrorpopup(true);
            SetDisplayErrorMessage("Something Went Wrong");
          }
        }
      } catch (e) {
        setmasterLoader(false);
        SetErrorpopup(true);
        SetDisplayErrorMessage("Something Went Wrong");
      }
    } // end of else for NOT reschedule
  };  // end of method Complete_delivery_status
  const Complete_delivery_status_exchange = async () => {
    let Apiresult: any;
    if (RescheduleEventId != null && RescheduleEventId != "") {
      setmasterLoader(true);
      console.log("cowokeeeerr", CoworkersExc)
      let NumberofCoworkers = "";
      if (CoworkersExc == "") {
        NumberofCoworkers = "1";
      } else {
        NumberofCoworkers = CoworkersExc;
      }
      let agrNum
      let InventoryArray
      let InventoryNumber
      const agrResponse = await getAgreementInfo(exchangeAgreementId ? exchangeAgreementId : viewAgrData?.data?.agreementInformation?.agreementId)
      console.log(agrResponse, "agrResponsesdfghj")
      if (agrResponse.status == 200) {
        const agreementInfo = agrResponse.data;
        setAgreementResponse(agreementInfo)
        const InventoryArray = agreementInfo?.agreementInventories?.map(
          (Obj: any) => {
            return Obj.inventoryId;
          }
        );
        const InventoryNumber = agreementInfo?.agreementInventories?.map(
          (el: any) => {
            return { inventoryNumber: el.inventoryNumber };
          }
        );
        setInventoryNumberExc(InventoryNumber);
        console.log("InventoryArray", InventoryArray);
        Setinventoryidexc(InventoryArray);
        agrNum = agreementInfo?.agreementInformation?.agreementNumber
        setAgrNumberExc(agrNum)
        console.log({ agrNumberExc, agrNum }, "agrNumberExcasdfgh")

      }
      else {
        setmasterLoader(false);
        SetErrorpopup(true);
        SetDisplayErrorMessage("Something Went Wrong");
      }
      /*eslint-disable no-constant-condition*/
      if (deliveryStatus == "Cancelled") {
        const value = {
          agreementId: "98989898",
          isLoaner: "Y",
          loanerAction: {
            appointmentId: String(RescheduleEventId),
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            actionName: "Reopen",
            coWorkerId: employeeId,
          },
        };
        setmasterLoader(true);
        const result = await Confirmdelivery(value);
        setmasterLoader(false);
        console.log("result", result);
      }
      debugger
      const complete = {
        storeNumber: storeNumber,
        //address_id: IdAddress,
        eventInformation: {
          partyId: customerId,
          //agreementId: agreementId,
          inventoryIds: InventoryArray,
          type: props.isReturn === true ? "Return" : (DeliverytypeExc == 'P' || 'CI') ? "Return" : DeliverytypeExc,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          eventDate: moment(startDateExc).format("MM/DD/YYYY"),
          eventSource: "Store",
          //eventId: RescheduleEventId,
          timeSlot: {
            timeSlotId: exReturnTimeSlotId,
          },
          eventInstruction: InstructionsExc,
          requiredCoworkers: NumberofCoworkers ? NumberofCoworkers : '1',
          racdbSourced: true,
          agreementNumber: agrNum,
          customerName: cusName,
          isLoaner: "Y",
          loanerCreate: {
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            timeSlot: exReturnTimeSlotId,
            customerId: String(customerId),
            agreementNumber: agrNum,
            inventories: InventoryNumber,
            // coworkerId: "654644",
            appointmentId: String(RescheduleEventId),
            appointmentInfo: {
              appointmentType: !props.isReturn
                ? DeliverytypeExc
                : decideappointmentType(),
              appointmentSource: AppoinmentSource,
              appointmentDate: startDateExc,
              requiredCoworkers: NumberofCoworkers,
              instructions: InstructionsExc,
            },
          },
        },
      };

      console.log("Complete event reuest", complete);
      setmasterLoader(true)
      Apiresult = await FinishdeliveryStatus(complete);
      setmasterLoader(false)
      /* eslint-disable no-console */
      try {
        console.log(Apiresult.data);
        const nextamount = await getnextamountdue();
        console.log("1731", nextamount);
        await receiptstatus(nextamount);
        SetSaveTrigger(true);
        if (Apiresult.status == 200) {
          const ApiData = Apiresult.data;
          setmasterLoader(false);
          SetOpenreturnconfirmpopup(true);
          setNewAppointmentId(ApiData?.appointmentInfo?.appointmentId);
          GetTimeSlot(startDateExc, AppoinmentSource);
          let TrimmedZip = "";
          let TrimmedPlus4: any = "";
          if (Zip.length > 5) {
            TrimmedZip = Zip.slice(0, 5);
            TrimmedPlus4 = Zip.slice(6, Zip.length);
          } else {
            TrimmedZip = Zip;
          }
          const Value = {
            eventId: RescheduleEventId,
            deliveryType: DeliverytypeExc,
            addressId: IdAddress,
            addressLine1: Addressline1,
            addressLine2: Addressline2,
            zip: TrimmedZip,
            zipPlus4: TrimmedPlus4 != "" ? TrimmedPlus4 : null,
            city: City,
            state: State,
            timeSlotId: exReturnTimeSlotId,
            appointmentDate: moment(startDate).format("MM/DD/YYYY"),
            appointmentStartTime: ContextStartDate,
            appointmentEndTime: ContextEndDate,
            coWorkersRequired: CoworkersExc,
            instructions: InstructionsExc,
          };
          console.log("Context", Value);

          const some = {
            deliveryMethodStatus: "0",
            deliveryInformation: Value,
          };
          console.log("DeliveryValue", some);
          setdeliveryMethodData(some);
        } else {
          setmasterLoader(false);
          SetErrorpopup(true);
          SetDisplayErrorMessage("Something Went Wrong");
        }
      } catch (e) {
        setmasterLoader(false);
        SetErrorpopup(true);
        SetDisplayErrorMessage("Something Went Wrong");
      }
    } else {
      try {
        setmasterLoader(true);
        let NumberofCoworkers = "";
        if (CoworkersExc == "") {
          NumberofCoworkers = "1";
        } else {
          NumberofCoworkers = CoworkersExc;
        }
        let agrNum
        let InventoryArray
        let InventoryNumber
        const agrResponse = await getAgreementInfo(exchangeAgreementId ? exchangeAgreementId : viewAgrData?.data?.agreementInformation?.agreementId)
        console.log(agrResponse, "agrResponsesdfghj")
        if (agrResponse.status == 200) {
          const agreementInfo = agrResponse.data;
          setAgreementResponse(agreementInfo)
          InventoryArray = agreementInfo?.agreementInventories?.map(
            (Obj: any) => {
              return Obj.inventoryId;
            }
          );
          InventoryNumber = agreementInfo?.agreementInventories?.map(
            (el: any) => {
              return { inventoryNumber: el.inventoryNumber };
            }
          );
          setInventoryNumberExc(InventoryNumber);
          console.log("InventoryArray", InventoryArray);
          Setinventoryidexc(InventoryArray);
          agrNum = agreementInfo?.agreementInformation?.agreementNumber
          setAgrNumberExc(agrNum)
          console.log({ agrNumberExc, agrNum }, "agrNumberExcasdfgh")
        }
        else {
          setmasterLoader(false);
          SetErrorpopup(true);
          SetDisplayErrorMessage("Something Went Wrong");
        }
        debugger

        const complete = {
          storeNumber: storeNumber,
          address_id: IdAddress,
          eventInformation: {
            partyId: customerId,
            agreementId: exchangeAgreementId ? exchangeAgreementId : viewAgrData?.data?.agreementInformation?.agreementId,
            inventoryIds: InventoryArray,
            type: props.isReturn === true ? "Return" : (DeliverytypeExc == 'P' || 'CI') ? "Return" : DeliverytypeExc,
            eventDate: moment(startDateExc).format("MM/DD/YYYY"),
            eventSource: "Store",
            timeSlot: {
              timeSlotId: exReturnTimeSlotId,
            },
            eventInstruction: InstructionsExc,
            requiredCoworkers: NumberofCoworkers ? NumberofCoworkers : '1',
            racdbSourced: true,
            agreementNumber: agrNum,
            customerName: cusName,
            isLoaner: "Y",
            loanerCreate: {
              storeNumber: window.sessionStorage.getItem("storeNumber"),
              timeSlot: exReturnTimeSlotId,
              customerId: String(customerId),
              agreementNumber: agrNum,
              inventories: InventoryNumber,
              // coworkerId: "654644",
              appointmentInfo: {
                appointmentType: !props.isReturn
                  ? DeliverytypeExc
                  : decideappointmentType(),
                appointmentSource: AppoinmentSource,
                appointmentDate: startDateExc,
                requiredCoworkers: NumberofCoworkers,
                instructions: InstructionsExc,
              },
            },
          },
        };

        console.log("Complete event reuest", complete);
        setmasterLoader(true);
        Apiresult = await FinishdeliveryStatus(complete);
        /* eslint-disable no-console */
        console.log(Apiresult.data);
        setmasterLoader(false);
        SetSaveTrigger(true);
        if (Apiresult.status == 200) {
          setmasterLoader(false);
          const ApiData = Apiresult.data;
          setNewAppointmentId(ApiData?.appointmentInfo?.appointmentId);
          // SetOpendeliveryconfirmpopup(true);
          const request = {
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            eventInformation: {
              partyId: "33925768",
              agreementId: exchangeAgreementId,
              inventoryIds: ["9999207382277"],
              type: "Delivery",
              eventDate: "11/23/2021",
              eventSource: "Store",
              timeSlot: {
                timeSlotId: "9999100000392",
              },
              eventInstructions: "created customer event",
              requiredCoworkers: "2",
              racdbSourced: true,
              isLoaner: "N",
            },
            address_id: addressId,
          };
          setmasterLoader(true)
          const result = await FinishdeliveryStatus(request);
          setmasterLoader(false)
        }
        // else {
        //   SetErrorpopup(true);
        //   SetDisplayErrorMessage("Something Went Wrong");
        // }
      } catch (e) {
        setmasterLoader(false);
        SetErrorpopup(true);
        SetDisplayErrorMessage("Something Went Wrong");
      }
    }
    return Apiresult
  };

  const formatDate = (dates: any) => {
    const dateFormat = new Date(dates);
    console.log(dateFormat, "<--- Date Format");
    console.log(dateFormat.toUTCString(), "UTC");
    return (
      dateFormat.toUTCString().slice(0, 4) +
      dateFormat.toUTCString().slice(7, 12) +
      dateFormat.toUTCString().slice(5, 7)
    );
  };

  const buildTimeSlot = (isExchangeReturnFlow?: any) => {
    console.log(isExchangeReturnFlow, "isExchangeReturnFlow in builtime")
    if (isExchangeReturnFlow) {
      if (timeSlotDataExc != undefined && timeSlotDataExc.length > 0) {
        let slotNumbers = 0;
        return timeSlotDataExc.map((objdate: any) => {
          if (
            objdate.TimeSlots != null &&
            objdate.TimeSlots != "" &&
            slotNumbers < timeslotCount
          ) {
            slotNumbers++;
            const formattedDate = formatDate(objdate.Date);
            return (
              // eslint-disable-next-line react/jsx-key
              <Grid
                item
                className={`${classes.agrTimeSlot} ${classes1.agrTimeSlot}`}
              >
                <Typography
                  className={`${classes.semiBold} ${classes.font14} ${classes.MarginDate}`}
                >
                  {formattedDate}
                </Typography>
                <Grid className={classes.agrListGroup}>
                  {timeslotbindExc(objdate, isExchangeReturnFlow)}
                </Grid>
              </Grid>
            );
          }
        });
      } else {
        return (
          <div className={classes.Norecords}>
            <CircularProgress />
          </div>
        );
      }
    }
    else {
      if (timeSlotData != undefined && timeSlotData.length > 0) {
        let slotNumbers = 0;
        return timeSlotData.map((objdate: any) => {
          if (
            objdate.TimeSlots != null &&
            objdate.TimeSlots != "" &&
            slotNumbers < timeslotCount
          ) {
            slotNumbers++;
            const formattedDate = formatDate(objdate.Date);
            return (
              // eslint-disable-next-line react/jsx-key
              <Grid
                item
                className={`${classes.agrTimeSlot} ${classes1.agrTimeSlot}`}
              >
                <Typography
                  className={`${classes.semiBold} ${classes.font14} ${classes.MarginDate}`}
                >
                  {formattedDate}
                </Typography>
                <Grid className={classes.agrListGroup}>
                  {timeslotbind(objdate)}
                </Grid>
              </Grid>
            );
          }
        });
      } else {
        return (
          <div className={classes.Norecords}>
            <CircularProgress />
          </div>
        );
      }
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const timeslotbind = (objdate: any, isExchangeReturnFlow?: any) => {
    // var itr = 0;
    if ( masterLoader) return;
    console.log('I am the One triggered for the OnChange', objdate)

    console.log("enters in the else", isExchangeReturnFlow)

    if (objdate.TimeSlots != undefined) {
      console.log("entersdfgh")
      return objdate.TimeSlots.map((objtime: any) => {
        // itr += 1;
        const modifiedStarttime = moment(objtime.startTime, "HH:mm:ss").format(
          "LT"
        );
        const modifiedEndtime = moment(objtime.endTime, "HH:mm:ss").format(
          "LT"
        );
        const slicedTimeslot =
          modifiedStarttime.slice(0, 5) +
          modifiedStarttime.slice(8, modifiedStarttime.length);

        let modifiedStarttimeExc
        let modifiedEndtimeExc
        let slicedTimeslotExc
        // inside the condition
        if (isExchangeReturnFlow) {
          modifiedStarttimeExc = moment(objtime.startTime, "HH:mm:ss").format(
            "LT"
          );
          modifiedEndtimeExc = moment(objtime.endTime, "HH:mm:ss").format(
            "LT"
          );
          slicedTimeslotExc =
            modifiedStarttimeExc.slice(0, 5) +
            modifiedStarttimeExc.slice(8, modifiedStarttimeExc.length);
        }
        console.log(slicedTimeslotExc, "slicedTimeslotExcsdfghj")

        const Currently = new Date();
        const todayDate = String(Currently.getDate()).padStart(2, "0");
        const todayMonth = String(Currently.getMonth() + 1).padStart(2, "0"); //January is 0!
        const todayYear = Currently.getFullYear();

        const todaysDate = todayMonth + "/" + todayDate + "/" + todayYear;
        const TodayUpdatedDate = new Date(todaysDate);
        const ObjUpdatedDate = new Date(objdate.Date);
        if (isExchangeReturnFlow) {
          if (
            TodayUpdatedDate == ObjUpdatedDate ||
            TodayUpdatedDate > ObjUpdatedDate
          ) {
            const startTime: any = objtime.startTime;
            const hoursdisplayed = startTime.split(":")[0];
            const minutesdisplayed = startTime.split(":")[1];
            const displayedTime = hoursdisplayed + ":" + minutesdisplayed;
            console.log(displayedTime);

            const Currently = new Date();
            const TimeatPresent = new Date(Currently.getTime());
            const currentHours = TimeatPresent.getHours();
            console.log(currentHours);
            const currentMinutes = TimeatPresent.getMinutes();
            console.log(currentMinutes);
            const Currenttime = currentHours + ":" + currentMinutes;
            console.log(Currenttime);

            if (objtime.status !== "Available") {
              let slotColor = "";

              if (
                exReturnBindtimeslotId !== "" &&
                exReturnBindtimeslotId == objtime.timeSlot &&
                ExBindtimeslotDate !== "" &&
                ExBindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotBusy;
              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  id={objtime.timeSlot}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttimeExc, modifiedEndtimeExc, isExchangeReturnFlow)
                  }
                  disabled={true}
                >
                  {slicedTimeslotExc} - {modifiedEndtimeExc}
                </button>
              );
            } else if (
              todaysDate > objdate.Date ||
              (todaysDate == objdate.Date && displayedTime <= Currenttime)
              // eslint-disable-next-line sonarjs/no-duplicated-branches
            ) {
              let slotColor = "";

              if (
                exReturnBindtimeslotId !== "" &&
                exReturnBindtimeslotId == objtime.timeSlot &&
                ExBindtimeslotDate !== "" &&
                ExBindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotBusy;

              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  id={objtime.timeSlot}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttimeExc, modifiedEndtimeExc)
                  }
                  disabled={true}
                >
                  {slicedTimeslotExc} - {modifiedEndtimeExc}
                </button>
              );
            } else {
              let slotColor = "";

              if (
                exReturnBindtimeslotId !== "" &&
                exReturnBindtimeslotId == objtime.timeSlot &&
                ExBindtimeslotDate !== "" &&
                ExBindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotAvailable;
              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  id={objtime.timeSlot}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttimeExc, modifiedEndtimeExc, isExchangeReturnFlow)
                  }
                >
                  {slicedTimeslotExc} - {modifiedEndtimeExc}
                </button>
              );
            }
          } else {
            if (objtime.status !== "Available") {
              return (
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  id={objtime.timeSlot}
                  className={classes.slotBusy}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttimeExc, modifiedEndtimeExc)
                  }
                  disabled={true}
                >
                  {slicedTimeslotExc} - {modifiedEndtimeExc}
                </button>
              );
            } else {
              console.log("enter thxcvbncvbn", slicedTimeslotExc)
              let slotColor = "";
              if (
                exReturnBindtimeslotId !== "" &&
                exReturnBindtimeslotId == objtime.timeSlot &&
                ExBindtimeslotDate !== "" &&
                ExBindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotAvailable;
              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  // value={modifiedtimeExc ? slicetimeExc : slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  value={slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  id={objtime.timeSlot}
                  // className={modifiedtimeExc ? colourExc : slotColor}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttimeExc, modifiedEndtimeExc, isExchangeReturnFlow)
                  }
                >
                  {slicedTimeslotExc} - {modifiedEndtimeExc}
                </button>
              );
            }
          }
        }

        else { // working
          // debugger
          if (
            TodayUpdatedDate == ObjUpdatedDate ||
            TodayUpdatedDate > ObjUpdatedDate
          ) {
            const startTime: any = objtime.startTime;
            const hoursdisplayed = startTime.split(":")[0];
            const minutesdisplayed = startTime.split(":")[1];
            const displayedTime = hoursdisplayed + ":" + minutesdisplayed;
            console.log(displayedTime);

            const Currently = new Date();
            const TimeatPresent = new Date(Currently.getTime());
            const currentHours = TimeatPresent.getHours();
            console.log(currentHours);
            const currentMinutes = TimeatPresent.getMinutes();
            console.log(currentMinutes);
            const Currenttime = currentHours + ":" + currentMinutes;
            console.log(Currenttime);

            if (objtime.status !== "Available") {
              let slotColor = "";

              if (
                BindtimeslotId !== "" &&
                BindtimeslotId == objtime.timeSlot &&
                BindtimeslotDate !== "" &&
                BindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotBusy;
              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslot + "-" + modifiedEndtime}
                  id={objtime.timeSlot}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttime, modifiedEndtime)
                  }
                  disabled={true}
                >
                  {slicedTimeslot} - {modifiedEndtime}
                </button>
              );
            } else if (
              todaysDate > objdate.Date ||
              (todaysDate == objdate.Date && displayedTime <= Currenttime)
              // eslint-disable-next-line sonarjs/no-duplicated-branches
            ) {
              let slotColor = "";

              if (
                BindtimeslotId !== "" &&
                BindtimeslotId == objtime.timeSlot &&
                BindtimeslotDate !== "" &&
                BindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotBusy;
              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslot + "-" + modifiedEndtime}
                  id={objtime.timeSlot}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttime, modifiedEndtime)
                  }
                  disabled={true}
                >
                  {slicedTimeslot} - {modifiedEndtime}
                </button>
              );
            } else {
              let slotColor = "";

              if (
                BindtimeslotId !== "" &&
                BindtimeslotId == objtime.timeSlot &&
                BindtimeslotDate !== "" &&
                BindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotAvailable;
              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslot + "-" + modifiedEndtime}
                  id={objtime.timeSlot}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttime, modifiedEndtime)
                  }
                >
                  {slicedTimeslot} - {modifiedEndtime}
                </button>
              );
            }
          }
          else {
            if (objtime.status !== "Available") {
              return (
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslot + "-" + modifiedEndtime}
                  id={objtime.timeSlot}
                  className={classes.slotBusy}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttime, modifiedEndtime)
                  }
                  disabled={true}
                >
                  {slicedTimeslot} - {modifiedEndtime}
                </button>
              );
            } else { // working
              let slotColor = "";
              if (
                BindtimeslotId !== "" &&
                BindtimeslotId == objtime.timeSlot &&
                BindtimeslotDate !== "" &&
                BindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotAvailable;
              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslot + "-" + modifiedEndtime}
                  id={objtime.timeSlot}
                  className={slotColor}
                  onClick={(e) => {
                    getslotdetails(e, modifiedStarttime, modifiedEndtime)
                    // setslicetimeExc(slicedTimeslot + "-" + modifiedEndtime)
                    // colourExc = slotColor
                    // setcolourExc(colourExc)
                    // setmodifiedEndtimeExc(true)
                  }
                  }
                >
                  {slicedTimeslot} - {modifiedEndtime}
                </button>
              );
            }
          }
        }
      });
      // }
    }
  };

  const timeslotbindExc = (objdate: any, isExchangeReturnFlow?: any) => {
    // var itr = 0;
    if ( masterLoader) return;
    console.log('I am the One triggered for the OnChange', objdate)

    console.log("enters in the else", isExchangeReturnFlow)

    if (objdate.TimeSlots != undefined) {
      console.log("entersdfgh")
      return objdate.TimeSlots.map((objtime: any) => {
        // itr += 1;
        // const modifiedStarttime = moment(objtime.startTime, "HH:mm:ss").format(
        //   "LT"
        // );
        // const modifiedEndtime = moment(objtime.endTime, "HH:mm:ss").format(
        //   "LT"
        // );
        // const slicedTimeslot =
        //   modifiedStarttime.slice(0, 5) +
        //   modifiedStarttime.slice(8, modifiedStarttime.length);

        let modifiedStarttimeExc
        let modifiedEndtimeExc
        let slicedTimeslotExc
        // inside the condition
        if (isExchangeReturnFlow) {
          modifiedStarttimeExc = moment(objtime.startTime, "HH:mm:ss").format(
            "LT"
          );
          modifiedEndtimeExc = moment(objtime.endTime, "HH:mm:ss").format(
            "LT"
          );
          slicedTimeslotExc =
            modifiedStarttimeExc.slice(0, 5) +
            modifiedStarttimeExc.slice(8, modifiedStarttimeExc.length);
        }
        console.log(slicedTimeslotExc, "slicedTimeslotExcsdfghj")

        const Currently = new Date();
        const todayDate = String(Currently.getDate()).padStart(2, "0");
        const todayMonth = String(Currently.getMonth() + 1).padStart(2, "0"); //January is 0!
        const todayYear = Currently.getFullYear();

        const todaysDate = todayMonth + "/" + todayDate + "/" + todayYear;
        const TodayUpdatedDate = new Date(todaysDate);
        const ObjUpdatedDate = new Date(objdate.Date);
        if (isExchangeReturnFlow) {
          if (
            TodayUpdatedDate == ObjUpdatedDate ||
            TodayUpdatedDate > ObjUpdatedDate
          ) {
            const startTime: any = objtime.startTime;
            const hoursdisplayed = startTime.split(":")[0];
            const minutesdisplayed = startTime.split(":")[1];
            const displayedTime = hoursdisplayed + ":" + minutesdisplayed;
            console.log(displayedTime);

            const Currently = new Date();
            const TimeatPresent = new Date(Currently.getTime());
            const currentHours = TimeatPresent.getHours();
            console.log(currentHours);
            const currentMinutes = TimeatPresent.getMinutes();
            console.log(currentMinutes);
            const Currenttime = currentHours + ":" + currentMinutes;
            console.log(Currenttime);

            if (objtime.status !== "Available") {
              let slotColor = "";

              if (
                exReturnBindtimeslotId !== "" &&
                exReturnBindtimeslotId == objtime.timeSlot &&
                ExBindtimeslotDate !== "" &&
                ExBindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotBusy;
              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  id={objtime.timeSlot}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttimeExc, modifiedEndtimeExc, isExchangeReturnFlow)
                  }
                  disabled={true}
                >
                  {slicedTimeslotExc} - {modifiedEndtimeExc}
                </button>
              );
            } else if (
              todaysDate > objdate.Date ||
              (todaysDate == objdate.Date && displayedTime <= Currenttime)
              // eslint-disable-next-line sonarjs/no-duplicated-branches
            ) {
              let slotColor = "";

              if (
                exReturnBindtimeslotId !== "" &&
                exReturnBindtimeslotId == objtime.timeSlot &&
                ExBindtimeslotDate !== "" &&
                ExBindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotBusy;

              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  id={objtime.timeSlot}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttimeExc, modifiedEndtimeExc)
                  }
                  disabled={true}
                >
                  {slicedTimeslotExc} - {modifiedEndtimeExc}
                </button>
              );
            } else {
              let slotColor = "";

              if (
                exReturnBindtimeslotId !== "" &&
                exReturnBindtimeslotId == objtime.timeSlot &&
                ExBindtimeslotDate !== "" &&
                ExBindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotAvailable;
              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  id={objtime.timeSlot}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttimeExc, modifiedEndtimeExc, isExchangeReturnFlow)
                  }
                >
                  {slicedTimeslotExc} - {modifiedEndtimeExc}
                </button>
              );
            }
          } else {
            if (objtime.status !== "Available") {
              return (
                <button
                  type="button"
                  name={objdate.Date}
                  value={slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  id={objtime.timeSlot}
                  className={classes.slotBusy}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttimeExc, modifiedEndtimeExc)
                  }
                  disabled={true}
                >
                  {slicedTimeslotExc} - {modifiedEndtimeExc}
                </button>
              );
            } else {
              console.log("enter thxcvbncvbn", slicedTimeslotExc)
              let slotColor = "";
              if (
                exReturnBindtimeslotId !== "" &&
                exReturnBindtimeslotId == objtime.timeSlot &&
                ExBindtimeslotDate !== "" &&
                ExBindtimeslotDate == objdate.Date
              ) {
                slotColor = classes.slotBooked;
              } else {
                slotColor = classes.slotAvailable;
              }
              return (
                //
                <button
                  type="button"
                  name={objdate.Date}
                  // value={modifiedtimeExc ? slicetimeExc : slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  value={slicedTimeslotExc + "-" + modifiedEndtimeExc}
                  id={objtime.timeSlot}
                  // className={modifiedtimeExc ? colourExc : slotColor}
                  className={slotColor}
                  onClick={(e) =>
                    getslotdetails(e, modifiedStarttimeExc, modifiedEndtimeExc, isExchangeReturnFlow)
                  }
                >
                  {slicedTimeslotExc} - {modifiedEndtimeExc}
                </button>
              );
            }
          }
        }

        // else { // working
        //   // debugger
        //   if (
        //     TodayUpdatedDate == ObjUpdatedDate ||
        //     TodayUpdatedDate > ObjUpdatedDate
        //   ) {
        //     const startTime: any = objtime.startTime;
        //     const hoursdisplayed = startTime.split(":")[0];
        //     const minutesdisplayed = startTime.split(":")[1];
        //     const displayedTime = hoursdisplayed + ":" + minutesdisplayed;
        //     console.log(displayedTime);

        //     const Currently = new Date();
        //     const TimeatPresent = new Date(Currently.getTime());
        //     const currentHours = TimeatPresent.getHours();
        //     console.log(currentHours);
        //     const currentMinutes = TimeatPresent.getMinutes();
        //     console.log(currentMinutes);
        //     const Currenttime = currentHours + ":" + currentMinutes;
        //     console.log(Currenttime);

        //     if (objtime.status !== "Available") {
        //       let slotColor = "";

        //       if (
        //         BindtimeslotId !== "" &&
        //         BindtimeslotId == objtime.timeSlot &&
        //         BindtimeslotDate !== "" &&
        //         BindtimeslotDate == objdate.Date
        //       ) {
        //         slotColor = classes.slotBooked;
        //       } else {
        //         slotColor = classes.slotBusy;
        //       }
        //       return (
        //         //
        //         <button
        //           type="button"
        //           name={objdate.Date}
        //           value={slicedTimeslot + "-" + modifiedEndtime}
        //           id={objtime.timeSlot}
        //           className={slotColor}
        //           onClick={(e) =>
        //             getslotdetails(e, modifiedStarttime, modifiedEndtime)
        //           }
        //           disabled={true}
        //         >
        //           {slicedTimeslot} - {modifiedEndtime}
        //         </button>
        //       );
        //     } else if (
        //       todaysDate > objdate.Date ||
        //       (todaysDate == objdate.Date && displayedTime <= Currenttime)
        //       // eslint-disable-next-line sonarjs/no-duplicated-branches
        //     ) {
        //       let slotColor = "";

        //       if (
        //         BindtimeslotId !== "" &&
        //         BindtimeslotId == objtime.timeSlot &&
        //         BindtimeslotDate !== "" &&
        //         BindtimeslotDate == objdate.Date
        //       ) {
        //         slotColor = classes.slotBooked;
        //       } else {
        //         slotColor = classes.slotBusy;
        //       }
        //       return (
        //         //
        //         <button
        //           type="button"
        //           name={objdate.Date}
        //           value={slicedTimeslot + "-" + modifiedEndtime}
        //           id={objtime.timeSlot}
        //           className={slotColor}
        //           onClick={(e) =>
        //             getslotdetails(e, modifiedStarttime, modifiedEndtime)
        //           }
        //           disabled={true}
        //         >
        //           {slicedTimeslot} - {modifiedEndtime}
        //         </button>
        //       );
        //     } else {
        //       let slotColor = "";

        //       if (
        //         BindtimeslotId !== "" &&
        //         BindtimeslotId == objtime.timeSlot &&
        //         BindtimeslotDate !== "" &&
        //         BindtimeslotDate == objdate.Date
        //       ) {
        //         slotColor = classes.slotBooked;
        //       } else {
        //         slotColor = classes.slotAvailable;
        //       }
        //       return (
        //         //
        //         <button
        //           type="button"
        //           name={objdate.Date}
        //           value={slicedTimeslot + "-" + modifiedEndtime}
        //           id={objtime.timeSlot}
        //           className={slotColor}
        //           onClick={(e) =>
        //             getslotdetails(e, modifiedStarttime, modifiedEndtime)
        //           }
        //         >
        //           {slicedTimeslot} - {modifiedEndtime}
        //         </button>
        //       );
        //     }
        //   }
        //   else {
        //     if (objtime.status !== "Available") {
        //       return (
        //         <button
        //           type="button"
        //           name={objdate.Date}
        //           value={slicedTimeslot + "-" + modifiedEndtime}
        //           id={objtime.timeSlot}
        //           className={classes.slotBusy}
        //           onClick={(e) =>
        //             getslotdetails(e, modifiedStarttime, modifiedEndtime)
        //           }
        //           disabled={true}
        //         >
        //           {slicedTimeslot} - {modifiedEndtime}
        //         </button>
        //       );
        //     } else { // working
        //       let slotColor = "";
        //       if (
        //         BindtimeslotId !== "" &&
        //         BindtimeslotId == objtime.timeSlot &&
        //         BindtimeslotDate !== "" &&
        //         BindtimeslotDate == objdate.Date
        //       ) {
        //         slotColor = classes.slotBooked;
        //       } else {
        //         slotColor = classes.slotAvailable;
        //       }
        //       return (
        //         //
        //         <button
        //           type="button"
        //           name={objdate.Date}
        //           value={slicedTimeslot + "-" + modifiedEndtime}
        //           id={objtime.timeSlot}
        //           className={slotColor}
        //           onClick={(e) => {
        //             getslotdetails(e, modifiedStarttime, modifiedEndtime)
        //             // setslicetimeExc(slicedTimeslot + "-" + modifiedEndtime)
        //             // colourExc = slotColor
        //             // setcolourExc(colourExc)
        //             // setmodifiedEndtimeExc(true)
        //           }
        //           }
        //         >
        //           {slicedTimeslot} - {modifiedEndtime}
        //         </button>
        //       );
        //     }
        //   }
        // }
      });
      // }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const Addressselect = () => {
    return (
      <div>
        {masterLoader ? (
          <Grid
            style={{
              position: "fixed",
              background: "#f7f5f5",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              opacity: 0.6,
              zIndex: 1050,
              textAlign: "center",
              margin: "0px 0px",
            }}
          >
            <Grid
              style={{
                display: "block",
                position: "fixed",
                zIndex: 9999999,
                top: "40%",
                right: "50%",
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid className={classes.fixTableHead}>
          <RACTable
            // renderTableHead={RenderTable}
            renderTableContent={RenderTableBody}
          />
        </Grid>

        <Grid item md={12} className={classes.modalFooter}>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerMR4}
            onClick={cancelbtn}
          >
            Cancel
          </RACButton>

          <RACButton
            variant="contained"
            color="primary"
            // disabled={enableOkpopup}
            onClick={Okpopup}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const CoworkerOptionsArray = () => {
    console.log("enters the options", coWorkerRequiredArray)
    if (coWorkerRequiredArray !== undefined) {
      console.log("enters the options if")

      return coWorkerRequiredArray.map((entry: any) => {
        const some: any = entry.toString();
        if (entry == "Select") {
          return { label: entry, value: "1" };
        } else {
          return { label: entry, value: some };
        }
      });
    } else {
      console.log("enters the options else")
      return [{ label: "Select", value: "0" }];
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const Timebind = (event: any, exchangeReturn?: any) => {
    debugger;
    if (exchangeReturn) {
      setExReturnTime(event.target.value);
    }
    else {
      SetTime(event.target.value);
    }
    SetSaveTrigger(false);
  };
  // const AddressgridHead = [
  //   {
  //     displayName: 'Address Line 1*',
  //   },
  //   {
  //     displayName: 'Address Line 2',
  //   },
  //   {
  //     displayName: 'Zip*',
  //   },
  //   {
  //     displayName: 'City*',
  //   },
  //   {
  //     displayName: 'State*',
  //   },
  // ];

  // const AddressgridBody =[
  //   {
  //     displayAddress:
  //   }
  // ]

  // const RenderTable = () => (
  //   <>
  //     {AddressgridHead.map((value, index) => {
  //       return <RACTableCell key={index}>{value.displayName}</RACTableCell>;
  //     })}
  //   </>
  // );

  const RenderTableBody = () => (
    <>
      <>
        <RACTableRow>
          <RACTableCell className={classes.AddressPopup}>
            Address Line 1{" "}
            <Box component="span" className={classes.Mandatory}>
              {" "}
              *{" "}
            </Box>
          </RACTableCell>

          <RACTableCell className={classes.AddressPopup}>
            Address Line 2
          </RACTableCell>
          <RACTableCell className={classes.AddressPopup}>
            Zip{" "}
            <Box component="span" className={classes.Mandatory}>
              {" "}
              *{" "}
            </Box>
          </RACTableCell>
          <RACTableCell className={classes.AddressPopup}>
            City{" "}
            <Box component="span" className={classes.Mandatory}>
              {" "}
              *{" "}
            </Box>
          </RACTableCell>
          <RACTableCell className={classes.AddressPopup}>
            State{" "}
            <Box component="span" className={classes.Mandatory}>
              {" "}
              *{" "}
            </Box>
          </RACTableCell>
        </RACTableRow>
      </>
      <RACTableRow>
        <RACTableCell>
          <RACTextbox
            // type="text"
            value={Line1Popup}
            maxlength={30}
            name="addressLine1"
            OnChange={(e) => handleChange(e)}
            disabled={InputGrid}
            isCurrency={false}
          />
        </RACTableCell>
        <RACTableCell>
          <RACTextbox
            type="text"
            value={Line2Popup}
            maxlength={30}
            name="addressLine2"
            OnChange={() => handleChange(event)}
            disabled={InputGrid}
            isCurrency={false}
          />
        </RACTableCell>

        <RACTableCell>
          <RACTextbox
            // type="phoneno"
            maxlength={10}
            value={ZipPopup}
            name="zip"
            OnChange={(e) => handleChange(e)}
            disabled={InputGrid}
            isCurrency={false}
          />
        </RACTableCell>

        <RACTableCell>
          <RACTextbox
            type="text"
            value={CityPopup}
            maxlength={30}
            name="city"
            OnChange={() => handleChange(event)}
            disabled={InputGrid}
            isCurrency={false}
          />
        </RACTableCell>

        <RACTableCell>
          <RACSelect
            options={Loadstate()}
            loading={StateconditionLoader}
            // defaultValue={StatePopup}
            defaultValue={StatePopup !== "" ? StatePopup : "Select"}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (e.target.value == "Select") {
                SetStatePopup("");
              } else {
                SetStatePopup(e.target.value);
              }
            }}
            isDisabled={InputGrid}
            {...(Statedrop.length == 1 &&
              !conditionLoader && {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              errorMessage: "Unable to fetch Data",
            })}
          />
        </RACTableCell>
        <RACTableCell>
          <RACButton onClick={() => addGridRow()} disabled={enableadd}>
            <Addimage />
          </RACButton>
        </RACTableCell>
      </RACTableRow>
      {bindGrid()}
    </>
  );
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const addressDoctorPopup = () => {
    return (
      <Grid
        item
        md={12}
        id="confirmduedate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        {masterLoader ? (
          <Grid
            style={{
              position: "fixed",
              background: "#f7f5f5",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              opacity: 0.6,
              zIndex: 1050,
              textAlign: "center",
              margin: "0px 0px",
            }}
          >
            <Grid
              style={{
                display: "block",
                position: "fixed",
                zIndex: 9999999,
                top: "40%",
                right: "50%",
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : SuggestedAddress == undefined ? (
          <React.Fragment>
            <Typography className={classes.RACPopupMsg}>
              {addressErrorMsg}
            </Typography>
            <Grid
              item
              md={12}
              className={`${classes.textCenter} ${classes.spacerMT2}`}
            >
              <RACButton
                variant="contained"
                color="primary"
                onClick={() => {
                  continueclick("ENT");
                }}
              >
                Ok
              </RACButton>
            </Grid>
            {/* )} */}
          </React.Fragment>
        ) : (
          <Grid item md={12} className={classes.spacerPX2}>
            <Typography className={classes.RACPopupMsg}>
              Entered address is not located
            </Typography>
            <Grid item md={12}>
              <Typography className={classes.RACPopupMsg}>
                Suggested Addresses
              </Typography>
              <div className={classes.validateCheck}>
                <RACRadio
                  id="inlineRadio1"
                  name="inlineRadioOptions"
                  checked={defaultSuggestedAddress == "SUG" ? true : false}
                  onClick={() => RadioBtnclick("SUG")}
                  value={"SUG"}
                />
                <Typography variant="caption" className={classes.RACPopupMsg}>
                  {SuggestedAddress !== undefined &&
                    SuggestedAddress !== null &&
                    SuggestedAddress.Addresses !== null &&
                    // SuggestedAddress.value.Addresses !== null &&
                    SuggestedAddress.Addresses.StreetAddress !== undefined
                    ? `${SuggestedAddress.Addresses.StreetAddress},`
                    : ""}
                  {SuggestedAddress !== undefined &&
                    SuggestedAddress !== null &&
                    // SuggestedAddress.value !== null &&
                    SuggestedAddress.Addresses !== null &&
                    SuggestedAddress.Addresses.City !== undefined
                    ? `${SuggestedAddress.Addresses.City},`
                    : ""}
                  {SuggestedAddress !== undefined &&
                    SuggestedAddress !== null &&
                    // SuggestedAddress.value !== null &&
                    SuggestedAddress.Addresses !== null &&
                    SuggestedAddress.Addresses.StateProvince !== undefined
                    ? `${SuggestedAddress.Addresses.StateProvince},`
                    : ""}
                  {SuggestedAddress !== undefined &&
                    SuggestedAddress !== null &&
                    // SuggestedAddress.value !== null &&
                    SuggestedAddress.Addresses !== null &&
                    SuggestedAddress.Addresses.PostalCode !== undefined
                    ? `${SuggestedAddress.Addresses.PostalCode}`
                    : ""}
                </Typography>
              </div>
            </Grid>
            <Grid item md={12}>
              <Typography className={classes.RACPopupMsg}>
                Entered Address
              </Typography>
              <div className={classes.validateCheck}>
                <RACRadio
                  id="inlineRadio2"
                  name="inlineRadioOptions"
                  checked={defaultSuggestedAddress == "ENT" ? true : false}
                  onClick={() => RadioBtnclick("ENT")}
                  value={"ENT"}
                />
                {Line2Popup != "" ? (
                  <Typography variant="caption" className={classes.RACPopupMsg}>
                    {Line1Popup}, {Line2Popup ? Line2Popup : ""}, {CityPopup},{" "}
                    {StatePopup}, {ZipPopup}
                  </Typography>
                ) : (
                  <Typography variant="caption" className={classes.RACPopupMsg}>
                    {Line1Popup}, {CityPopup}, {StatePopup}, {ZipPopup}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid container className={classes.dInherit}>
              <Grid className={classes.floatLeft}>
                <RACButton
                  variant="outlined"
                  color="primary"
                  onClick={() => setValidateIsOpen(false)}
                >
                  Cancel
                </RACButton>
              </Grid>
              <Grid className={classes.floatRight}>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.spacerMR4}
                  onClick={() => continueclick("ENT")}
                  disabled={BtnIsdisabled1}
                >
                  Override
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  disabled={BtnIsdisabled2}
                  onClick={() => continueclick("SUG")}
                >
                  Continue
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const errorpopup = () => {
    return (
      <div
        id="success"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textCenter}>
          <Alertimage></Alertimage>
          <Typography className={classes.my2}>{DisplayErrorMessage}</Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => CloseErrorPopup()}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const DeliveryConfirmPopupOpen = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Successimage></Successimage>
          <Typography className={classes.mt3}>
            Delivery Scheduled Successfully.
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={DeliveryScheduled}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const DeliveryReturnConfirmPopupOpen = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Successimage></Successimage>
          <Typography className={classes.mt3}>
            Delivery and Return Scheduled Successfully.
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={DeliveryScheduled}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  // const BookedtimeSlotpopup = () => {
  //   return (
  //     <div
  //       id="success"
  //       data-bs-backdrop="static"
  //       data-bs-keyboard="false"
  //       aria-hidden="true"
  //     >
  //       <Grid item md={12} className={classes.textCenter}>
  //         <Alertimage></Alertimage>
  //         <Typography className={classes.my2}>
  //           Selected Slot is Already Booked. Please Choose a different Time
  //         </Typography>
  //       </Grid>

  //       <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
  //         <RACButton
  //           variant="contained"
  //           color="primary"
  //           isRounded={false}
  //           onClick={() => SetOpenTimeslotbookedPopup(false)}
  //         >
  //           Ok
  //         </RACButton>
  //       </Grid>
  //     </div>
  //   );
  // };

  const ReturnDelivery = async () => {
    setmasterLoader(true);
    const response = await getAgreementDelivery(agreementId, appointmentId);
    if (response.status == 200) {
      setmasterLoader(false);
      const result = await GetDeliveryInformationMapper(
        viewAgrData?.data,
        response.data
      );
      console.log("ReturnDeliveryResult", result);
      setdeliveryMethodData(result);
      //setdeliveryMethodData(response.data);
      RescheduledDelivery();
    }
  };
  const DeliveryScheduled = () => {
    SetOpendeliveryconfirmpopup(false);
    SetOpenreturnconfirmpopup(false)
    if (
      props !== undefined &&
      props.isDeliveryReschedule !== undefined &&
      props.isDeliveryReschedule.deliveryrescheduling != undefined &&
      props.isDeliveryReschedule.deliveryrescheduling === true && props.isReturn == true
    ) {
      SetOpendeliveryconfirmpopup(false);
      SetOpenreturnconfirmpopup(false)

      //RescheduledDelivery();
      window.location.reload()
    }
    else if (props !== undefined &&
      props.isDeliveryReschedule !== undefined &&
      props.isDeliveryReschedule.deliveryrescheduling != undefined &&
      props.isDeliveryReschedule.deliveryrescheduling === true) {
      SetOpendeliveryconfirmpopup(false);
      SetOpenreturnconfirmpopup(false)

      ReturnDelivery();
    }
    else if (props.isReturn === true) {
      console.log("isDeliveryReschedulkkkkkkk");
      SetOpendeliveryconfirmpopup(false);
      SetOpenreturnconfirmpopup(false)

    } else {
      history.push({
        pathname: `/dashboard`,
      });
      setBindtimeslotId("");
      SetExReturnBindtimeslotId("")
      // BindtimeslotId = '';
      BindtimeslotDate = "";
      ExBindtimeslotDate = "";
    }
  };

  const RescheduledDelivery = () => {
    props.rescheduleDatas({ data: true });
  };

  const CloseErrorPopup = () => {
    SetErrorpopup(false);
  };

  const PreviousbuttonClick = () => {
    if (redirectOrigin === AMOrigin) {
      history.push({
        pathname: `${AppRoute.AMCustomer}/${customerId}`,
      });
      return;
    }
    history.push({
      pathname: `/agreement/rental/details/${customerId}/${agreementId}`,
    });

    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };

  const formatPhone = (input: any) => {
    const cleaned = ("" + input).replace(/\D/g, "");
    //This is raw data to pass on props
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return cleaned.replace(PhoneRegex, "($1) $2-$3");
  };
  function ValidateEmail(inputText: any) {
    // eslint-disable-next-line no-useless-escape
    const mailformat = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return mailformat.test(inputText);
  }
  const welcomeValidateOnChange = (e: any) => {
    if (e.target.name == "phoneno") {
      const cleaned = ("" + e.target.value).replace(/\D/g, "");
      console.log("cleaned", cleaned.length);
      if (cleaned.length <= 10) {
        console.log("njnjnjn");

        const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const phoneFormat = "($1) $2-$3";
        const temp = cleaned.replace(PhoneRegex, phoneFormat);
        setphoneNo(temp);
        setphoneErrMsg("");
        temp == "" || temp == undefined ? setphoneErrMsg("") : null;
      }
    } else if (e.target.name == "Email") {
      if (e.target.value == "") {
        //setemailErrMsg(true)
        setemailText(e.target.value);
        // setwelcomeEnable(true);
      } else {
        if (!ValidateEmail(e.target.value)) {
          setemailErrMsg("Please enter valid Email");
          setwelcomeEnable(true);
          setemailText(e.target.value);
        } else {
          setwelcomeEnable(false);
          setemailErrMsg("");
          setemailText(e.target.value);
        }

        // setwelcomeEnable(false)
      }
    }
  };
  const resumeSuccessPopup = () => {
    return (
      <div>
        <Grid item md={12} className={globalStyle.textCenter}>
          <Successimage></Successimage>
          <Typography
            className={`${globalStyle.agrDeliveryReceiptText} ${globalStyle.semiBold}`}
          >
            Welcome video sent
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${globalStyle.py4} ${globalStyle.textCenter}`}
        >
          <RACButton
            className={globalStyle.mx1}
            variant="contained"
            color="primary"
            onClick={() => setResendsuccess(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  //Return Methods
  const ReturnChange = () => {
    console.log("hhihh", viewAgrData?.data);
    if (viewAgrData?.data) {
      const AgrData = viewAgrData?.data;
      if (AgrData?.agreementWorkflow?.isDeliveryCompleted == 0) {
        if (
          AgrData?.agreementWorkflow?.isInitialPaymentPaid == 1 &&
          AgrData?.agreementInformation?.isLastReceiptReversed == 1
        ) {
          SetReturnDeliveryCompleted(false);
          setSOTwoFactorReturnEnabled(true);
        } else {
          SetReturnDeliveryCompleted(true);
        }
      } else {
        setSOTwoFactorReturnEnabled(true);
        SetReturnDeliveryCompleted(false);
      }
    }
  };
  const SOTwoFactorReturnCompleted = (e) => {
    if (e) {
      setreturnbtnRdn(true);
      setconfirmFlow(false);
    }
    // history.push({
    //   pathname:`/agreement/info/details/${customerId}/${agreementId}?type=REV`
    // })
    //     // communicate to Routes that URL has changed
    //     const navEvent = new PopStateEvent('popstate');
    //     window.dispatchEvent(navEvent);
  };
  const ReversePaymentInfoPopContent = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Please reverse the payment to return the agreement?
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="secondary"
            onClick={() => {
              SetReturnDeliveryCompleted(false);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              SetReturnDeliveryCompleted(false);
              setReverseConfirmation(true);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const ReversePaymentConfirmationContent = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Do you want to reverse the payment?
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="secondary"
            onClick={() => {
              setReverseConfirmation(false);
            }}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setReverseConfirmation(false);
              history.push({
                pathname: `/payment1/reversepayment/${customerId}`,
                search: `?agreementId=${agreementId}`,
              });
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };
  const ResendVideoFinishClick = async () => {
    if (phoneNo.length <= 9 && !sendEmail) {
      if (phoneNo == "") {
        setphoneErrMsg("Please enter phone number");
      } else {
        setphoneErrMsg("Please enter valid phone number");
      }
    } else if (emailText == "" && sendEmail) {
      setemailErrMsg("Please enter email");
    } else {
      setphoneErrMsg("");
      setemailErrMsg("");
      setResendVopen(false);
      console.log("emailTextojjkj", emailText);
      console.log("phoneNojkjkj", phoneNo);
      const refCode = sendEmail ? "SSKYES" : "SSKYTS";
      const payload = {
        customerId: customerId,
        agreementId: agreementId,
        emailId: emailText,
        phoneNumber: phoneNo?.replace(/\D/g, ""),
        isSendMail: true,
        sendEmailType: refCode,
      };
      setmasterLoader(true);
      const response = await sendEPO(payload);
      console.log("responsesendEPO", response);

      if (response.statusCode == 200) {
        setmasterLoader(false);
        setResendsuccess(true);
        // const agrInfoData = agreementInfoData
        // agrInfoData.agreementWorkflow.isWelcomeVideoSent ='1';
        // agrInfoData.welcomeVideoInfo.activityDate = moment(new Date()).format('DD/MM/YYYY');
        // agrInfoData.welcomeVideoInfo.activityDesc = `Text was sent to ${sendEmail?emailText:phoneNo}`;
        // agrInfoData.welcomeVideoInfo.activityRefCode = refCode;

        // setAgreementInfoData(agrInfoData)
      }
    }

    // if(emailText ==''){
    //   setemailErrMsg(true);
    // }
    // temp =='' || temp ==undefined ?setwelcomeEnable(true):setwelcomeEnable(false)
  };
  const sendAgreementPopup = () => {
    return (
      <Grid className={globalStyle.p3}>
        <Grid container>
          <Grid item md={12} className={globalStyle.mb3}>
            <Typography
              component="label"
              className={`${globalStyle.formLabel} ${globalStyle.w100} ${globalStyle.fontBold}`}
            >
              How do you want the welcome video to be shared?
            </Typography>
          </Grid>
          <Grid item md={12} className={globalStyle.mb3}>
            <RACRadio
              value={sendEmail}
              checked={sendEmail}
              onChange={() => {
                setsendEmail(true);
                settextMsg(false);
              }}
              label="Email"
            />
            <RACRadio
              value={textMsg}
              checked={textMsg}
              onChange={() => {
                setsendEmail(false);
                settextMsg(true);
                setwelcomeEnable(false);
              }}
              label="Text Message"
            />
          </Grid>
          <Grid item md={6}>
            {sendEmail ? (
              <div>
                <RACTextbox
                  inputlabel="Email"
                  inputLabelClassname="form-label"
                  name="Email"
                  value={emailText != undefined ? emailText : ""}
                  OnChange={(e) => welcomeValidateOnChange(e)}
                  isCurrency={false}
                  type="email"
                  errorMessage={emailErrMsg}
                />
              </div>
            ) : (
              <div>
                <RACTextbox
                  inputlabel="Phone Number"
                  inputLabelClassname="form-label"
                  name="phoneno"
                  value={phoneNo != undefined ? formatPhone(phoneNo) : ""}
                  OnChange={(e) => welcomeValidateOnChange(e)}
                  isCurrency={false}
                  type="text"
                  errorMessage={phoneErrMsg}
                />
              </div>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${globalStyle.pb4} ${globalStyle.px3} ${globalStyle.textRight}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.mx1}
            name="SendAgreementCancel"
            onClick={() => {
              setResendVopen(false);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            disabled={welcomeEnable}
            className={classes.mx1}
            name=""
            onClick={() => ResendVideoFinishClick()}
          >
            Finish
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const ReturnStableFunc = () => {
    setreturnbtnRdn(false);
  };

  // const deliveryCancel = () => {
  //   history.push({
  //     pathname: `/customer/searchcustomer`,
  //   });
  // };

  const PreviousTimeSlotFn = async () => {
    let date1: any = ''
    let date2: any = ''
    date2 = date2 = new Date(timeSlotData[0]['Date'])
    date1 = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
    const differenceInMilliseconds = date2 - date1;
    let differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    differenceInDays = Math.ceil(differenceInDays)
    if (differenceInDays > 3) {
      differenceInDays = 3;
    }
    const previousDate = moment(timeSlotData[0]['Date']).subtract(differenceInDays, "days").format("YYYY-MM-DD");
    setmasterLoader(true)
    const GetTimeSlotRes = await GetTimeSlot(previousDate);
    setmasterLoader(false);
  }
  const NextTimeSlotFn = async () => {
    let nextDate = timeSlotData[3]['Date'];
    setmasterLoader(true)
    const GetTimeSlotRes = await GetTimeSlot(nextDate);
    setmasterLoader(false);
  }
  function suggestAddressFn() {
    return (
      <>
        <Grid item md={12} className={classes.spacerPX2}>
          <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
            Entered Address
          </Typography>
          <div className={classes.formCheck}>
            <RACRadio
              id="inlineRadio1"
              name="inlineRadioOptions"
              checked={defaultSuggestedAddress == 'ENT' ? true : false}
              onClick={() => setdefaultSuggestedAddress('ENT')}
              value={'ENT'}
            />
            <Typography
              className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
            >{`${Line2Popup ? Line1Popup + ', ' + Line2Popup : Line1Popup}` + ', ' + CityPopup + ', ' + StatePopup + ', ' + ZipPopup}
            </Typography>
          </div>
          <Grid
            item
            md={12}
            className={`${classes.spacerMB2} ${classes.spacerMT2}`}
          >
            <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
              Suggested Addresses
            </Typography>
            <div className={classes.formCheck}>
              <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultSuggestedAddress == 'GA' ? true : false}
                onClick={() => setdefaultSuggestedAddress('GA')}
                value={'GA'}
              />
              <Typography
                className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`} /* style={{width:'85%'}} */
              >
                {validatedAddresses.suggestedAddress}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.spacerMT4} ${classes.w100} ${classes.floatLeft}`}
        >
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.foc}
              onClick={() => setCanSuggestAddress(false)}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            <RACButton
              variant="contained"
              color="primary"

              onClick={() => continueclick(defaultSuggestedAddress)}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      </>
    )
  }
  function suggestErrorInAddress() {
    return (
      <>
        <Grid item md={12}>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
              marginBottom: '2%'

            }}
          >
            <Alertimage width={"10%"} height={"10%"} />
          </Grid>
          <Grid item md={12} className={classes.textCenter}>
            <Typography variant={'body2'} className={classes.RACPOPMsg}>
              {validatedAddresses?.message}
            </Typography>
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              variant="contained"
              color="primary"
              isRounded={false}
              onClick={() => setCantSuggestAddress(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    )
  }
  const verificationWarningPopupFn = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid item md={12} className="text-center">
          <WarningIcon></WarningIcon>
          <Typography style={{ marginTop: '18px', marginBottom: '10px', fontSize: '18px' }}>
            This customer has incomplete verification,Do you want to continue?
          </Typography>
        </Grid>
        <Grid container style={{ justifyContent: 'center' }}>
          <RACButton
            color='primary'
            variant='contained'
            style={{ marginRight: '10px' }}
            onClick={() => {
              setverificationWarningPopup(false);
              history.push(`/customer/update/${customerId}/customer`)

            }}

          >
            Verify Customer
          </RACButton>
          <RACButton
            color='primary'
            variant='outlined'
            onClick={() => {
              setverificationWarningPopup(false);
              setVerifcationRequiredContinue(true)
            }}
          >
            Continue
          </RACButton>
        </Grid>
      </Grid>
    );
  };


  const LMDInventoryStore = () => {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item sm={4} md={4} lg={2}>
            <RACSelect
              name="Radius Stores"
              inputLabel="Store Pick-up"
              options={radiusStores}
              defaultValue={radiusSelected}
              isDisabled={false}
              onChange={(
                e: React.ChangeEvent<HTMLSelectElement>
              ) => {
                setRadiusSelected(e.target.value);                
              }}
              {...(radiusStores?.length == 1 &&
                !conditionLoader && {
                errorMessage: "Unable to fetch Data",
              })}
            />

          </Grid>
          <Grid item sm={12} md={12} lg={8}>
            <br></br>
            <br></br>
            If selected a Store Pick-up Event will be created for the same time slot as the Delivery Event.
          </Grid>
        </Grid>
      </div>
    )
  }



  return (
    <div>
      {masterLoader ? (
        <Grid
          style={{
            position: "fixed",
            background: "#f7f5f5",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 1050,
            textAlign: "center",
            margin: "0px 0px",
          }}
        >
          <Grid
            style={{
              display: "block",
              position: "fixed",
              zIndex: 9999999,
              top: "40%",
              right: "50%",
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <div id="step-4" className={classes.tabPanel}>
        <Grid container className={openReturnContent ? `${classes.height90Vh}` : ''}>
          <Grid item md={12}>
            <Typography className={classes.title} variant="h6">
              {/* Delivery Information */}
              {props.isReturn
                ? "Return Information"
                : "Delivery Information"}{" "}
            </Typography>
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    sm={12}
                    md={12}
                    lg={3}
                    className={classes.DetailsPadding}
                  >
                    <Typography className={classes.deliverytype} variant="h5">
                      Delivery Type
                    </Typography>

                    <Grid className={classes.formCheck}>
                      <RACRadio
                        name="inlineRadioOptions"
                        id="delivery"
                        checked={checkDeliverytype == "DEL" ? true : false}
                        value={DeliveryRdo}
                        onClick={showDeliveryaddress}
                        size={"small"}
                        label={props.isReturn ? "Pickup" : "Delivery"}
                      />
                    </Grid>
                    <Grid className={classes.formCheck}>
                      <RACRadio
                        name="inlineRadioOptions"
                        id="carryout"
                        checked={checkDeliverytype == "CAR" ? true : false}
                        value={CarryoutRdo}
                        onClick={hideDeliveryaddress}
                        size={"small"}
                        label={props.isReturn ? "Carry In" : "Carryout"}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}
                    lg={9}
                    className={classes.DetailsPadding}
                  >
                    <Typography className={classes.deliverytype} variant="h5">
                      Delivery Address
                    </Typography>

                    <Grid container>
                      <Grid
                        item
                        sm={4}
                        md={4}
                        lg={2}
                        className={classes.widthAdjust16}
                      >
                        <Typography className={classes.formLabel}>
                          Address Line 1
                        </Typography>
                        <Typography className={classes.formLabelValue}>
                          {Addressline1}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        md={4}
                        lg={2}
                        className={classes.widthAdjust16}
                      >
                        <Typography className={classes.formLabel}>
                          Address Line 2
                        </Typography>
                        <Typography className={classes.formLabelValue}>
                          {Addressline2}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        sm={4}
                        md={4}
                        lg={2}
                        className={classes.widthAdjust16}
                      >
                        <Typography className={classes.formLabel}>
                          Zip
                        </Typography>
                        <Typography className={classes.formLabelValue}>
                          {Zip}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        md={4}
                        lg={2}
                        className={classes.widthAdjust16}
                      >
                        <Typography className={classes.formLabel}>
                          City
                        </Typography>
                        <Typography className={classes.formLabelValue}>
                          {City}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        md={4}
                        lg={2}
                        className={classes.widthAdjust13}
                      >
                        <Typography className={classes.formLabel}>
                          State
                        </Typography>
                        <Typography className={classes.formLabelValue}>
                          {State}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        md={4}
                        lg={2}
                        className={classes.widthAdjust16}
                      >
                        {!props.isReturn ? (
                          <>
                            {hideswitchoutbutton ? (
                              <RACButton
                                className={classes.buttonAddRows}
                                onClick={() => disPopup()}
                                disabled={datePickerEnable ? false : true}
                              >
                                Select Other Address
                              </RACButton>
                            ) : (
                              <RACButton
                                className={classes.buttonAddRows}
                                onClick={() => disPopup()}
                                disabled={datePickerEnable ? false : true}
                              >
                                Select Other Address
                              </RACButton>
                            )}
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {props.reScheduleData != undefined &&
                  props.reScheduleData.deliveryType != "" ? (
                  <Grid container className={classes.DetailsPadding}>
                    <Grid item md={12}>
                      {props.reScheduleData.deliveryType === "Delivery" &&
                        Deliverytype === "D" ? (
                        <Typography
                          className={classes.deliverytype}
                          variant="h5"
                        >
                          Delivery Details
                        </Typography>
                      ) : Deliverytype === "D" ? (
                        <Typography
                          className={classes.deliverytype}
                          variant="h5"
                        >
                          Delivery Details
                        </Typography>
                      ) : (
                        <Typography
                          className={classes.deliverytype}
                          variant="h5"
                        >
                          Carryout Details
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container className={classes.DetailsPadding}>
                    <Grid item md={12}>
                      {Deliverytype === "D" ? (
                        <Typography
                          className={classes.deliverytype}
                          variant="h5"
                        >
                          Delivery Details
                        </Typography>
                      ) : (
                        <Typography
                          className={classes.deliverytype}
                          variant="h5"
                        >
                          Carryout Details
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2} className={classes.mb3}>
                  <Grid item sm={4} md={4} lg={2}>
                    <RACDatePicker
                      label="Date"
                      value={startDate}
                      classes={{ input: dateError !== '' ? classes.bgdateError : '' }}
                      errorMessage={dateError}
                      onChange={(e) => dates(e)}
                      inputProps={{
                        min: moment().format("YYYY-MM-DD"),
                        max: MaxDate
                      }}
                      disabled={datePickerEnable ? false : true}
                    />
                  </Grid>
                  {/* dates.bind(event) */}
                  <Grid item sm={4} md={4} lg={2}>
                    <RACTextbox
                      isCurrency={false}
                      id="a11y_time"
                      disabled
                      value={Time}
                      inputlabel="Time"
                      OnChange={Timebind}
                    />
                  </Grid>
                  {props.reScheduleData != undefined &&
                    props.reScheduleData.deliveryType != "" ? (
                    <>
                      {props.reScheduleData.deliveryType === "Delivery" &&
                        Deliverytype === "D" ? (
                        <Grid item sm={4} md={4} lg={2}>
                          <RACSelect
                            inputLabel="# Co-workers Required"
                            loading={conditionLoader}
                            options={CoworkerOptionsArray()}
                            defaultValue={Coworkers !== "" ? Coworkers : "1"}
                            // defaultValue={'1'}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              SetCoworkers(e.target.value);
                              if (props.triggerdeliveryChanged) {
                                props.triggerdeliveryChanged();
                              }
                              SetSaveTrigger(false);
                            }}
                            {...(coWorkerRequiredArray.length == 1 &&
                              !conditionLoader && {
                              errorMessage: "Unable to fetch Data",
                            })}
                            isDisabled={datePickerEnable ? false : true}
                          />
                        </Grid>
                      ) : Deliverytype === "D" ? (
                        <Grid item sm={4} md={4} lg={2}>
                          <RACSelect
                            inputLabel="# Co-workers Required"
                            loading={conditionLoader}
                            options={CoworkerOptionsArray()}
                            defaultValue={Coworkers !== "" ? Coworkers : "1"}
                            // defaultValue={'1'}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                              // eslint-disable-next-line sonarjs/no-identical-functions
                            ) => {
                              SetCoworkers(e.target.value);
                              
                              if (props.triggerdeliveryChanged) {
                                props.triggerdeliveryChanged();
                              }
                              SetSaveTrigger(false);
                            }}
                            {...(coWorkerRequiredArray.length == 1 &&
                              !conditionLoader && {
                              errorMessage: "Unable to fetch Data",
                            })}
                            isDisabled={datePickerEnable ? false : true}
                          />
                        </Grid>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {Deliverytype === "D" ? (
                        <Grid item sm={4} md={4} lg={2}>
                          <RACSelect
                            inputLabel="# Co-workers Required"
                            loading={conditionLoader}
                            options={CoworkerOptionsArray()}
                            defaultValue={Coworkers !== "" ? Coworkers : "1"}
                            // defaultValue={'1'}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                              // eslint-disable-next-line sonarjs/no-identical-functions
                            ) => {
                              SetCoworkers(e.target.value);
                              
                              if (props.triggerdeliveryChanged) {
                                props.triggerdeliveryChanged();
                              }
                              SetSaveTrigger(false);
                            }}
                            {...(coWorkerRequiredArray.length == 1 &&
                              !conditionLoader && {
                              errorMessage: "Unable to fetch Data",
                            })}
                            isDisabled={datePickerEnable ? false : true}
                          />
                        </Grid>
                      ) : null}
                    </>
                  )}
                  <Grid item sm={8} md={8} lg={4}>
                    <RACTextbox
                      inputlabel="Instructions"
                      id="a11y_Instructions"
                      isCurrency={false}
                      value={Instructions}
                      OnChange={(e) => {
                        SetInstructions(e.target.value);
                        if (props.triggerdeliveryChanged) {
                          props.triggerdeliveryChanged();
                        }
                        SetSaveTrigger(false);
                      }}
                      disabled={datePickerEnable ? false : true}
                    />
                  </Grid>
                </Grid>


                {LMDConfigurable ? LMDInventoryStore() : ''}



                {/* (viewAgrData?.data?.agreementWorkflow?.isDeliveryScheduled == '1') && (viewAgrData?.data?.agreementWorkflow?.isSwitchoutEnabled == '0') && (viewAgrData?.data?.agreementInformation?.agreementType == 'Rental Agreement (RTO)' || viewAgrData?.data?.agreementInformation?.agreementType == 'Rent to Rent - Loaner') && (props.appiontmentType == 'D' || props.appiontmentType == 'CO' || deliveryMethodData?.appiontmentType == 'D' || deliveryMethodData?.appiontmentType == 'CO') &&  */}
                {(viewAgrData?.data?.agreementWorkflow?.isDeliveryScheduled == '1') && (getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus != 'CAN' || getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'CMP') && (featureFlagDetails?.FieldDevice == '1') ? (<Grid>
                  <Grid style={{ display: 'inline-block' }}>
                    <RACToggle
                      checked={manualSignatureEnabled}
                      toggleColor="#0d6efd"
                      OnClick={() => { manualSignatureEnabled ? setManualSignatureEnabled(false) : setManualSignatureEnabled(true) }}
                    />
                  </Grid>
                  <Typography style={{ display: 'inline' }}>{" "}Manual Signature</Typography>
                </Grid>) : setManualSignatureEnabled(true)
                }

                {getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'CAN' ? <div><p style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid #000', lineHeight: '0.1rem', margin: '10px 0 20px' }}><span style={{ background: '#fff', padding: '0 10px' }}>Event Status:<span style={{ backgroundColor: 'lightpink', color: 'red' }}>Cancelled</span></span></p></div>
                  : null}
                {rescheduleEnable && !desktopView ?
                  <>
                    <Grid container>
                      <Grid
                        item
                        sm={12}
                        md={12}
                        lg={8}
                        className={classes.spacerMT8}
                      >
                        <Grid className={classes.floatLeft}>
                          <Typography variant="h5" className={classes.subTitle}>
                            Select Available Timeslot
                          </Typography>
                          <Typography className={classes.deliveryEventDesc}>
                            Only 4 events can be added in one time slot.
                          </Typography>
                        </Grid>
                        <Grid className={classes.floatRight}>
                          <Grid className={`${classes.floatLeft} ${classes.me48}`}>
                            <span className={classes.circleAvailable} />
                            <span>Available</span>
                          </Grid>
                          <Grid
                            classes={{
                              root: clsx(classes.floatLeft, classes.spacerMR4),
                            }}
                          >
                            <span className={classes.circleBusy} />
                            <span>Busy</span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={12} className={classes.spacerMB5}>
                      <Grid className={`${classes.mt2} ${classes.me2} ${classes1.timeSlot}`}>
                        {buildTimeSlot()}
                      </Grid>
                      <Grid className={`${globalStyle.flexCenter} ${globalStyle.mt3}  ${classes1.dMdFlex}`}>
                        <RACButton variant="contained" color="primary"
                          onClick={() => { PreviousTimeSlotFn() }}
                          disabled={previousBtnEnable ? false : true}
                        >
                          Previous
                        </RACButton>
                        <RACButton variant="contained" color="primary"
                          onClick={() => { NextTimeSlotFn() }}
                        >
                          Next
                        </RACButton>
                      </Grid>
                    </Grid>
                  </> : rescheduleEnable && desktopView ?
                    <>
                      <Grid container>
                        <Grid
                          item
                          sm={12}
                          md={12}
                          lg={8}
                          className={classes.spacerMT8}
                        >
                          <Grid className={classes.floatLeft}>
                            <Typography variant="h5" className={classes.subTitle}>
                              Select Available Timeslot
                            </Typography>
                            <Typography className={classes.deliveryEventDesc}>
                              Only 4 events can be added in one time slot.
                            </Typography>
                          </Grid>
                          <Grid className={classes.floatRight}>
                            <Grid className={`${classes.floatLeft} ${classes.me48}`}>
                              <span className={classes.circleAvailable} />
                              <span>Available</span>
                            </Grid>
                            <Grid
                              classes={{
                                root: clsx(classes.floatLeft, classes.spacerMR4),
                              }}
                            >
                              <span className={classes.circleBusy} />
                              <span>Busy</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item md={12} className={classes.spacerMB5}>
                        <Grid className={`${classes.mt2} ${classes.me2} ${classes1.timeSlot}`}>
                          {buildTimeSlot()}
                        </Grid>
                      </Grid>

                      {
                        ((exchangeAgreementId && agrInfoResp?.agreementInformation?.agreementStatusRefCode != 'ARTS' && agrInfoResp?.agreementWorkflow?.isReturnScheduled == '0' && agrInfoResp?.agreementWorkflow?.isReturnConfirmed == '0') || (viewAgrData?.data?.agreementInformation?.isExchanged == 1 &&
                          viewAgrData?.data?.agreementInformation.isDeliveryConfirmed == 0 &&
                          viewAgrData?.agreementInformation.isDeliveryScheduled == 0
                        )) ?
                          <RACCheckBox
                            className={`${classes.floatLeft} ${classes.textCenter}`}
                            onClick={() => {
                              setOpenReturnContent(!openReturnContent);
                              setStartDateExc(moment().format("YYYY-MM-DD"))
                              setExReturnTime("")
                              SetExReturnBindtimeslotId("")
                              SetExReturnTimeSlotId("")
                              SetExcInstructions("")
                              SetCoworkersExc("")
                              SetcheckDeliverytypeExc("DEL")
                            }}
                            checked={openReturnContent}
                            disabled={Time ? false : true}
                            label="Schedule Pickup for the Exchange Agreement"
                          />
                          : null
                      }
                    </>
                    : null}

                {/* -----------------------Tiem slot end------------------------- */}
                {RescheduleProps === "Schedule" ? (
                  <Grid className={classes.fixedBottom}>
                    <Grid className={classes.floatLeft}>
                      <RACButton
                        variant="outlined"
                        color="primary"
                        className={classes.floatLeft}
                        onClick={() => PreviousbuttonClick()}
                      >
                        Previous
                      </RACButton>
                      {/* <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.floatLeft} ${classes.ms2}`}
                        onClick={() => deliveryCancel()}
                      >
                        Cancel
                      </RACButton> */}
                    </Grid>

                    <Grid
                      className={classes.floatRight}
                      style={{ display: "flex" }}
                    >
                      {resumebtnFeatureFlag == "1" &&
                        featureFlagDetails?.SundaySky == "1" ? (
                        <RACButton
                          variant="contained"
                          color="primary"
                          className={`${classes.floatLeft} ${classes.mx1}`}
                          // disabled={enableFinish}
                          onClick={() => setResendVopen(true)}
                        >
                          Resend Welcome Video
                        </RACButton>
                      ) : null}
                      { }
                      <RACButton
                        disabled={
                          viewAgrData?.data?.agreementWorkflow
                            ?.isTransferInitiated == 1
                            ? true
                            : false
                        }
                        className={classes.mx1}
                        onClick={ReturnChange}
                        variant="contained"
                        color="primary"
                      >
                        Return
                      </RACButton>
                      <AddCoCustomer
                        // coCustomerData={Cocustomer}
                        func={pull_data}
                      />
                      <Eposchedule epoData={EpoProps} />
                      <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.floatLeft}
                        disabled={enableFinish}
                        onClick={() => Complete_delivery_status()}
                      >
                        Finish
                      </RACButton>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid className={classes.fixedBottom}>
                    <Grid className={classes.floatLeft}>
                      <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.floatLeft}
                        disabled={enableFinish}
                        onClick={() => Complete_delivery_status()}
                      >
                        Save
                      </RACButton>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
            {
              openReturnContent ?
                // <Card className={`${classes.card} ${classes.spacerMT2}`}>
                //   <CardContent>
                <div id="step-4" className={`${classes.tabPanel} ${classes.spacerMT2}`}>
                  <Grid container>
                    <Grid item md={12}>
                      <Typography className={classes.title} variant="h6">
                        Return Information
                      </Typography>
                      <Card className={classes.card}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              sm={12}
                              md={12}
                              lg={3}
                              className={classes.DetailsPadding}
                            >
                              <Typography className={classes.deliverytype} variant="h5">
                                Pickup Type
                              </Typography>

                              <Grid className={classes.formCheck}>
                                <RACRadio
                                  name="inlineRadioOptions"
                                  id="delivery"
                                  checked={checkDeliverytypeExc == "DEL" ? true : false}
                                  value={DeliveryRdoExc}
                                  onClick={(e) => showDeliveryaddress(e, true)}
                                  size={"small"}
                                  label={"Pickup"}
                                />
                              </Grid>
                              <Grid className={classes.formCheck}>
                                <RACRadio
                                  name="inlineRadioOptions"
                                  id="carryout"
                                  checked={checkDeliverytypeExc == "CAR" ? true : false}
                                  value={CarryinRdoExc}
                                  onClick={(e) => hideDeliveryaddress(e, true)}
                                  size={"small"}
                                  label={"Carry In"}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              sm={12}
                              md={12}
                              lg={9}
                              className={classes.DetailsPadding}
                            >
                              <Typography className={classes.deliverytype} variant="h5">
                                Pickup Address
                              </Typography>

                              <Grid container>
                                <Grid
                                  item
                                  sm={4}
                                  md={4}
                                  lg={2}
                                  className={classes.widthAdjust16}
                                >
                                  <Typography className={classes.formLabel}>
                                    Address Line 1
                                  </Typography>
                                  <Typography className={classes.formLabelValue}>
                                    {Addressline1}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={4}
                                  md={4}
                                  lg={2}
                                  className={classes.widthAdjust16}
                                >
                                  <Typography className={classes.formLabel}>
                                    Address Line 2
                                  </Typography>
                                  <Typography className={classes.formLabelValue}>
                                    {Addressline2}
                                  </Typography>
                                </Grid>

                                <Grid
                                  item
                                  sm={4}
                                  md={4}
                                  lg={2}
                                  className={classes.widthAdjust16}
                                >
                                  <Typography className={classes.formLabel}>
                                    Zip
                                  </Typography>
                                  <Typography className={classes.formLabelValue}>
                                    {Zip}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={4}
                                  md={4}
                                  lg={2}
                                  className={classes.widthAdjust16}
                                >
                                  <Typography className={classes.formLabel}>
                                    City
                                  </Typography>
                                  <Typography className={classes.formLabelValue}>
                                    {City}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={4}
                                  md={4}
                                  lg={2}
                                  className={classes.widthAdjust13}
                                >
                                  <Typography className={classes.formLabel}>
                                    State
                                  </Typography>
                                  <Typography className={classes.formLabelValue}>
                                    {State}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={4}
                                  md={4}
                                  lg={2}
                                  className={classes.widthAdjust16}
                                >
                                  {/* {!props.isReturn ? (
                                        <>
                                          {hideswitchoutbutton ? (
                                            <RACButton
                                              className={classes.buttonAddRows}
                                              onClick={() => disPopup()}
                                              disabled={datePickerEnable ? false : true}
                                            >
                                              Select Other Address
                                            </RACButton>
                                          ) : (
                                            <RACButton
                                              className={classes.buttonAddRows}
                                              onClick={() => disPopup()}
                                              disabled={datePickerEnable ? false : true}
                                            >
                                              Select Other Address
                                            </RACButton>
                                          )}
                                        </>
                                      ) : null} */}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          {props.reScheduleData != undefined &&
                            props.reScheduleData.deliveryType != "" ? (
                            <Grid container className={classes.DetailsPadding}>
                              <Grid item md={12}>
                                {props.reScheduleData.deliveryType === "Pickup" &&
                                  DeliverytypeExc === "P" ? (
                                  <Typography
                                    className={classes.deliverytype}
                                    variant="h5"
                                  >
                                    Pickup Details
                                  </Typography>
                                ) : DeliverytypeExc === "P" ? (
                                  <Typography
                                    className={classes.deliverytype}
                                    variant="h5"
                                  >
                                    Pickup Details
                                  </Typography>
                                ) : (
                                  <Typography
                                    className={classes.deliverytype}
                                    variant="h5"
                                  >
                                    Carry In Details
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container className={classes.DetailsPadding}>
                              <Grid item md={12}>
                                {DeliverytypeExc === "P" ? (
                                  <Typography
                                    className={classes.deliverytype}
                                    variant="h5"
                                  >
                                    Pickup Details
                                  </Typography>
                                ) : (
                                  <Typography
                                    className={classes.deliverytype}
                                    variant="h5"
                                  >
                                    Carry In Details
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          )}
                          <Grid container spacing={2} className={classes.mb3}>
                            <Grid item sm={4} md={4} lg={2}>
                              <RACDatePicker
                                label="Date"
                                value={startDateExc}
                                classes={{ input: dateError !== '' ? classes.bgdateError : '' }}
                                errorMessage={dateError}
                                onChange={(e) => dates(e, true)}
                                inputProps={{
                                  min: moment().format("YYYY-MM-DD"),
                                  max: MaxDate
                                }}
                                disabled={datePickerEnable ? false : true}
                              />
                            </Grid>
                            {/* dates.bind(event) */}
                            <Grid item sm={4} md={4} lg={2}>
                              <RACTextbox
                                isCurrency={false}
                                id="a11y_time"
                                disabled
                                value={exReturnTime}
                                inputlabel="Time"
                                OnChange={(e) => {
                                  Timebind(e, true)
                                }}
                              />
                            </Grid>
                            {props.reScheduleData != undefined &&
                              props.reScheduleData.deliveryType != "" ? (
                              <>
                                {props.reScheduleData.deliveryType === "Pickup" &&
                                  DeliverytypeExc === "P" ? (
                                  <Grid item sm={4} md={4} lg={2}>
                                    <RACSelect
                                      inputLabel="# Co-workers Required"
                                      loading={conditionLoader}
                                      options={CoworkerOptionsArray()}
                                      defaultValue={CoworkersExc !== "" ? CoworkersExc : "1"}
                                      // defaultValue={'1'}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLSelectElement>
                                      ) => {
                                        SetCoworkersExc(e.target.value);
                                        if (props.triggerdeliveryChanged) {
                                          props.triggerdeliveryChanged();
                                        }
                                        SetSaveTrigger(false);
                                      }}
                                      {...(coWorkerRequiredArray.length == 1 &&
                                        !conditionLoader && {
                                        errorMessage: "Unable to fetch Data",
                                      })}
                                      isDisabled={datePickerEnable ? false : true}
                                    />
                                  </Grid>
                                ) : DeliverytypeExc === "P" ? (
                                  <Grid item sm={4} md={4} lg={2}>
                                    <RACSelect
                                      inputLabel="# Co-workers Required"
                                      loading={conditionLoader}
                                      options={CoworkerOptionsArray()}
                                      defaultValue={CoworkersExc !== "" ? CoworkersExc : "1"}
                                      // defaultValue={'1'}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLSelectElement>
                                        // eslint-disable-next-line sonarjs/no-identical-functions
                                      ) => {
                                        SetCoworkersExc(e.target.value);
                                        if (props.triggerdeliveryChanged) {
                                          props.triggerdeliveryChanged();
                                        }
                                        SetSaveTrigger(false);
                                      }}
                                      {...(coWorkerRequiredArray.length == 1 &&
                                        !conditionLoader && {
                                        errorMessage: "Unable to fetch Data",
                                      })}
                                      isDisabled={datePickerEnable ? false : true}
                                    />
                                  </Grid>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {DeliverytypeExc === "P" ? (
                                  <Grid item sm={4} md={4} lg={2}>
                                    <RACSelect
                                      inputLabel="# Co-workers Required"
                                      loading={conditionLoader}
                                      options={CoworkerOptionsArray()}
                                      defaultValue={CoworkersExc !== "" ? CoworkersExc : "1"}
                                      // defaultValue={'1'}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLSelectElement>
                                        // eslint-disable-next-line sonarjs/no-identical-functions
                                      ) => {
                                        SetCoworkersExc(e.target.value);
                                        
                                        if (props.triggerdeliveryChanged) {
                                          props.triggerdeliveryChanged();
                                        }
                                        SetSaveTrigger(false);
                                      }}
                                      {...(coWorkerRequiredArray.length == 1 &&
                                        !conditionLoader && {
                                        errorMessage: "Unable to fetch Data",
                                      })}
                                      isDisabled={datePickerEnable ? false : true}
                                    />
                                  </Grid>
                                ) : null}
                              </>
                            )}
                            <Grid item sm={8} md={8} lg={4}>
                              <RACTextbox
                                inputlabel="Instructions"
                                id="a11y_Instructions"
                                isCurrency={false}
                                value={InstructionsExc}
                                OnChange={(e) => {
                                  SetExcInstructions(e.target.value);
                                  if (props.triggerdeliveryChanged) {
                                    props.triggerdeliveryChanged();
                                  }
                                  SetSaveTrigger(false);
                                }}
                                disabled={datePickerEnable ? false : true}
                              />
                            </Grid>

                          </Grid>
                          {/* (viewAgrData?.data?.agreementWorkflow?.isDeliveryScheduled == '1') && (viewAgrData?.data?.agreementWorkflow?.isSwitchoutEnabled == '0') && (viewAgrData?.data?.agreementInformation?.agreementType == 'Rental Agreement (RTO)' || viewAgrData?.data?.agreementInformation?.agreementType == 'Rent to Rent - Loaner') && (props.appiontmentType == 'D' || props.appiontmentType == 'CO' || deliveryMethodData?.appiontmentType == 'D' || deliveryMethodData?.appiontmentType == 'CO') &&  */}
                          {/* {((viewAgrData?.data?.agreementWorkflow?.isDeliveryScheduled == '1') && (getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus != 'CAN' || getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'CMP') && (featureFlagDetails?.FieldDevice == '1')) || true ? (<Grid>
                                <Grid style={{ display: 'inline-block' }}>
                                  <RACToggle
                                    checked={manualSignatureEnabled}
                                    toggleColor="#0d6efd"
                                    OnClick={() => { manualSignatureEnabled ? setManualSignatureEnabled(false) : setManualSignatureEnabled(true) }}
                                  />
                                </Grid>
                                <Typography style={{ display: 'inline' }}>{" "}Manual Signature</Typography>
                              </Grid>) : setManualSignatureEnabled(true)
                              } */}

                          {getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'CAN' ? <div><p style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid #000', lineHeight: '0.1rem', margin: '10px 0 20px' }}><span style={{ background: '#fff', padding: '0 10px' }}>Event Status:<span style={{ backgroundColor: 'lightpink', color: 'red' }}>Cancelled</span></span></p></div>
                            : null}
                          {rescheduleEnable && !desktopView ?
                            <>
                              <Grid container>
                                <Grid
                                  item
                                  sm={12}
                                  md={12}
                                  lg={8}
                                  className={classes.spacerMT8}
                                >
                                  <Grid className={classes.floatLeft}>
                                    <Typography variant="h5" className={classes.subTitle}>
                                      Select Available Timeslot
                                    </Typography>
                                    <Typography className={classes.deliveryEventDesc}>
                                      Only 4 events can be added in one time slot.
                                    </Typography>
                                  </Grid>
                                  <Grid className={classes.floatRight}>
                                    <Grid className={`${classes.floatLeft} ${classes.me48}`}>
                                      <span className={classes.circleAvailable} />
                                      <span>Available</span>
                                    </Grid>
                                    <Grid
                                      classes={{
                                        root: clsx(classes.floatLeft, classes.spacerMR4),
                                      }}
                                    >
                                      <span className={classes.circleBusy} />
                                      <span>Busy</span>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item md={12} className={classes.spacerMB5}>
                                <Grid className={`${classes.mt2} ${classes.me2} ${classes1.timeSlot}`}>
                                  {buildTimeSlot(true)}
                                </Grid>
                                <Grid className={`${globalStyle.flexCenter} ${globalStyle.mt3}  ${classes1.dMdFlex}`}>
                                  <RACButton variant="contained" color="primary"
                                    onClick={() => { PreviousTimeSlotFn() }}
                                    disabled={previousBtnEnable ? false : true}
                                  >
                                    Previous
                                  </RACButton>
                                  <RACButton variant="contained" color="primary"
                                    onClick={() => { NextTimeSlotFn() }}
                                  >
                                    Next
                                  </RACButton>
                                </Grid>
                              </Grid>

                            </> : rescheduleEnable && desktopView ?
                              <>
                                <Grid container>
                                  <Grid
                                    item
                                    sm={12}
                                    md={12}
                                    lg={8}
                                    className={classes.spacerMT8}
                                  >
                                    <Grid className={classes.floatLeft}>
                                      <Typography variant="h5" className={classes.subTitle}>
                                        Select Available Timeslot
                                      </Typography>
                                      <Typography className={classes.deliveryEventDesc}>
                                        Only 4 events can be added in one time slot.
                                      </Typography>
                                    </Grid>
                                    <Grid className={classes.floatRight}>
                                      <Grid className={`${classes.floatLeft} ${classes.me48}`}>
                                        <span className={classes.circleAvailable} />
                                        <span>Available</span>
                                      </Grid>
                                      <Grid
                                        classes={{
                                          root: clsx(classes.floatLeft, classes.spacerMR4),
                                        }}
                                      >
                                        <span className={classes.circleBusy} />
                                        <span>Busy</span>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item md={12} className={classes.spacerMB5}>
                                  <Grid className={`${classes.mt2} ${classes.me2} ${classes1.timeSlot}`}>
                                    {buildTimeSlot(true)}
                                  </Grid>
                                </Grid>
                              </>
                              : null}
                          {/* -----------------------Tiem slot end------------------------- */}
                          {RescheduleProps === "Schedule" ? (
                            <Grid className={classes.fixedBottom}>
                              <Grid className={classes.floatLeft}>
                                <RACButton
                                  variant="outlined"
                                  color="primary"
                                  className={classes.floatLeft}
                                  onClick={() => PreviousbuttonClick()}
                                >
                                  Previous
                                </RACButton>
                                {/* <RACButton
                                  variant="outlined"
                                  color="primary"
                                  className={`${classes.floatLeft} ${classes.ms2}`}
                                  onClick={() => deliveryCancel()}
                                >
                                  Cancel
                                </RACButton> */}
                              </Grid>

                              <Grid
                                className={classes.floatRight}
                                style={{ display: "flex" }}
                              >
                                {resumebtnFeatureFlag == "1" &&
                                  featureFlagDetails?.SundaySky == "1" ? (
                                  <RACButton
                                    variant="contained"
                                    color="primary"
                                    className={`${classes.floatLeft} ${classes.mx1}`}
                                    // disabled={enableFinish}
                                    onClick={() => setResendVopen(true)}
                                  >
                                    Resend Welcome Video
                                  </RACButton>
                                ) : null}
                                { }
                                <RACButton
                                  disabled={
                                    viewAgrData?.data?.agreementWorkflow
                                      ?.isTransferInitiated == 1
                                      ? true
                                      : false
                                  }
                                  className={classes.mx1}
                                  onClick={ReturnChange}
                                  variant="contained"
                                  color="primary"
                                >
                                  Return
                                </RACButton>
                                <AddCoCustomer
                                  // coCustomerData={Cocustomer}
                                  func={pull_data}
                                />
                                <Eposchedule epoData={EpoProps} />
                                <RACButton
                                  variant="contained"
                                  color="primary"
                                  className={classes.floatLeft}
                                  disabled={enableFinish}
                                  onClick={() => {
                                    Complete_delivery_status(true);
                                    // Complete_delivery_status_exchange();
                                  }}
                                >
                                  Finish
                                </RACButton>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid className={classes.fixedBottom}>
                              <Grid className={classes.floatLeft}>
                                <RACButton
                                  variant="contained"
                                  color="primary"
                                  className={classes.floatLeft}
                                  disabled={enableFinish}
                                  onClick={() => {
                                    Complete_delivery_status(true);
                                    // Complete_delivery_status_exchange();
                                  }}
                                >
                                  Save
                                </RACButton>
                              </Grid>
                            </Grid>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
                //   </CardContent>
                // </Card> 
                : null
            }
          </Grid>
        </Grid>
      </div>
      {
        returnbtnRdn ? (
          <AgreementReturn
            confirmFlow={confirmFlow}
            paymentredirectFlow={false}
            printRestrict={false}
            InvisibleFlow={() => {
              console.log("ih");
            }}
            ReturnStable={ReturnStableFunc}
            scheduleReturn={true}
          />
        ) : null
      }
      {/* Delivery Method ends here  */}
      {
        SOTwoFactorReturnEnabled ? (
          <SecondFactor
            setTwoFactorCompleted={SOTwoFactorReturnCompleted}
            setTwoFactorCancelClick={() => setSOTwoFactorReturnEnabled(false)}
            closeSLAClicked={() => setSOTwoFactorReturnEnabled(false)}
            currentRole={currentUserRole}
            moduleName="Return"
          //throwCurrentPage="SwitchoutScenario"
          />
        ) : null
      }
      {/* Delivery Address Pop-Up */}

      <RACModalCard
        isOpen={Popup}
        borderRadius="25px"
        maxWidth="lg"
        title="Select Delivery Address"
        onClose={() => SetPopup(false)}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={Addressselect()}
      />

      {/* please reverse payment */}
      <RACModalCard
        isOpen={ReturnDeliveryCompleted}
        onClose={() => SetReturnDeliveryCompleted(false)}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={ReversePaymentInfoPopContent()}
      />
      {/* please reverse payment confirmation */}
      <RACModalCard
        isOpen={ReverseConfirmation}
        onClose={() => setReverseConfirmation(false)}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={ReversePaymentConfirmationContent()}
      />
      <RACModalCard
        isOpen={ValidateIsOpen}
        borderRadius="15px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setValidateIsOpen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={addressDoctorPopup()}
        title="Validation Message"
      />

      <RACModalCard
        isOpen={ResendVopen}
        closeIcon={true}
        maxWidth="sm"
        title="Resend Welcome Video"
        TitleVariantClassName={globalStyle.popupTitle}
        // eslint-disable-next-line react/no-children-prop
        children={sendAgreementPopup()}
        onClose={() => setResendVopen(false)}
        borderRadius={"20px !important"}
      />
      <RACModalCard
        isOpen={resendsuccess}
        maxWidth="xs"
        // eslint-disable-next-line react/no-children-prop
        children={resumeSuccessPopup()}
        borderRadius={"20px !important"}
      />
      <RACModalCard
        isOpen={Errorpopup}
        maxWidth="xs"
        borderRadius="15px"
        // title="Modal title"
        closeIcon={true}
        onClose={() => SetErrorpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={errorpopup()}
      />
      <RACModalCard
        isOpen={Opendeliveryconfirmpopup}
        borderRadius="15px"
        // onClose={() => SetOpendeliveryconfirmpopup(false)}
        maxWidth="xs"
        // closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={DeliveryConfirmPopupOpen()}
      />

      <RACModalCard
        isOpen={Openreturnconfirmpopup}
        borderRadius="15px"
        // onClose={() => SetOpendeliveryconfirmpopup(false)}
        maxWidth="xs"
        // closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={DeliveryReturnConfirmPopupOpen()}
      />
      {/* <RACModalCard
        isOpen={OpenTimeslotbookedPopup}
        onClose={() => SetOpenTimeslotbookedPopup(false)}
        maxWidth="xs"
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={BookedtimeSlotpopup()}
      /> */}
      {/* <Userscontext.Provider value={obj}>   
        <Footer></Footer>   
      </Userscontext.Provider> */}
      {
        canEnableGoogleValdation ?
          (
            <>
              <RACModalCard
                isOpen={canSuggestAddress}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => setCanSuggestAddress(false)}
                // eslint-disable-next-line react/no-children-prop
                children={suggestAddressFn()}
                title="Validation Message"
              />
              <RACModalCard
                isOpen={cantSuggestAddress}
                maxWidth="xs"
                //  closeIcon={true}
                borderRadius="20px"
                //  onClose={() => setCantSuggestAddress(false)}
                // eslint-disable-next-line react/no-children-prop
                children={suggestErrorInAddress()}
              />
              <RACModalCard
                isOpen={verificationWarningPopup}
                maxWidth={'sm'}
                borderRadius={'25px !important'}
                closeIcon={true}
                onClose={() => {
                  setverificationWarningPopup(false);
                  setVerifcationRequiredContinue(true)
                }}

                //eslint-disable-next-line react/no-children-prop
                children={verificationWarningPopupFn()}
              ></RACModalCard>
            </>
          )
          : null
      }
    </div >
  );
}