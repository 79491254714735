/* eslint-disable prettier/prettier */

/* eslint-disable no-console */
export const ProductType = 'RPDAGR';

export enum ProductPermissions {
  AGREEMENT = 'AGREEMENT',
}

export const AMOrigin = 'am-customer';
export const AccountManagementURLParam = `?origin=${AMOrigin}`;
export const SACrolesArray = [
  "FSC-CAR",
  "CAR",
  "RPMT",
  "FSC-AM",
  "AM",
  "FSC-LAM",
  "LAM",
  "FSC-SMT",
  "SMT",
  "FSC-SM",
  "SM",
  "FSC-SSM",
  "SSM",
  "CAM",
  "FSC-DMT",
  "DMT",
  "FSC-DM",
  "DM",
  "FSC-RDT",
  "RDT",
  "FSC-RD",
  "RD"
];
export const ADD_ON_PACKAGE = 'Add-On Package'
export const ADD_ON_PRICING = 'AddOnPricing'

export const getPrimaryPhoneNumber = (phoneDetails: any) => {
  console.log('initial execution', phoneDetails)
  const activePhoneNumber: any = []
  const primaryPhoneNumber: any = []
  for (let phoneNumber = 0; phoneNumber < phoneDetails.length; phoneNumber++) {
    if (phoneDetails[phoneNumber]?.primary == 'Y') {
      primaryPhoneNumber.push(phoneDetails[phoneNumber])
      break;
    }
    if (phoneDetails[phoneNumber]?.active == 'Y' && activePhoneNumber.length == 0) {
      activePhoneNumber.push(phoneDetails[phoneNumber])
    }
  }
  console.log('primaryPhoneNumber', primaryPhoneNumber, activePhoneNumber)
  return primaryPhoneNumber.length > 0 ? primaryPhoneNumber : activePhoneNumber
}

export enum DE_APPROVAL_STATUS_TEXT {
  APPROVED = 'Approved',
  CONDITIONAL_APPROVAL = 'Approved',
  DECLINED = 'Declined',
  EXPIRED = 'DE Expired',
  NO_DE = 'No DE',
}

export enum DE_APPROVAL_STATUS {
  APPROVED = 'A',
  CONDITIONAL_APPROVAL = 'M',
  DECLINED = 'D',
  EXPIRED = 'E',
}

